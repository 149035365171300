
@media (min-width: 05px) and (max-width: 500px){


/* ================================ */
/* Navbar */
/* ================================ */
*{
  margin: 0;
  padding: 0;
}
.navbarcontainer{
  background-color: transparent; 
  position: relative;
  z-index: 99;
  /* box-shadow: 1px 1px 167px 1px orange; */

}
.navbarboxs{
display: flex;
justify-content: space-between;
width: 80%;
margin: auto;
}
.navlogobackground {
  background-color: white;
    width: 6rem;
    height: 6rem;
    position: absolute;
    left: 30px;
    z-index: 23;

}
.navbarbox .navbarimgbox img{
  width: 5rem;
  /* padding: 1rem; */
  position: absolute;
  z-index: 30;
  margin-top: 1rem;
  /* box-shadow: 1px 1px 167px 1px orange; */


}

.menubutton i{
  font-size: 2.4rem;
  padding-top: 1.7rem;
  font-weight: bold;
  
}
.navbarbox ul{
  display: flex;
  padding-right: 10rem;
  display: none;

}
.navbarbox ul li{
  list-style: none;
  margin: 1rem;

}
.navbarbox ul li a{
  text-decoration: none;
  color: black;
  z-index: 99;
  font-weight: bold;
  font-size: 1.3rem;

}









/* toolbar */

.toolbar{
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  margin-left: 20px;
margin-top: 10px;
/* box-shadow: 1px 1px 1px 1px rgb(152, 152, 178); */
}

.imglogo img{
width: 40px;
margin-right: 17px;
}
.sidebar{
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  background-color: black;
  color: white;
  width: 280px;
  transform:translateX(-100%) ;
  transition: all 0.4s;
  z-index: 10000;
}


.downbar{
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 40%;
  background-color: black;
  color: white;
  width: 100%;
  transform:translateY(0%) ;
  transition: all 0.4s;
}
.profile{
  display: flex;
  text-align: center;
  flex-direction: column;
  margin: 20px 80px;
  width: 100px;
}

.homelinks li{
margin: 15px 30px;
font-size: 17px;
list-style: none;
}
.homelinks li a{
color: white;
text-decoration: none;
}
.homelinks li i{
margin-right: 30px;
}
.profileimg img{
  width: 100px;
  height: 100px;
  border-radius: 73px;
}



.sidebaropen{
  transform:translateX(0%) ;
  
}
.downbaropen{
  transform:translateY(0%) ;
  
}
.backdrop{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  display: none;
  z-index: 999;
}

.backdropopen{
  display: block;
}













/* ====================== */
/* home */
/* ====================== */

.homecontaner{
  width: 100%;
  /* height: 100vh; */
  /* justify-content: center; */
  /* text-align: center; */
  display: flex;
  /* background-image: linear-gradient(45deg, rgb(224 218 207),rgb(208 202 202),rgb(209 209 215)); */
  background-image: linear-gradient(180deg, White 20%,orange);
z-index: 2;
margin-top: -5rem;
}
.homeboxs{
display: flex;
width: 90%;
margin: auto;
justify-content: center;
position: relative;
padding-top: 9rem;
padding-bottom: 5rem;
flex-wrap: wrap;

}
.homebox:first-child{
margin-right: 0rem;

}
.homebox{
  width: 30rem;
  z-index: 5;
  color: black;

}
.homebox h2{
  font-weight: bolder;
  padding: 0.4rem 0rem;
  font-size: 1.5rem;
  /* font-family: 'Inter',sans-serif; */
}
.homebox h1{
  font-weight: bold;
  padding: 1rem 0rem;
  font-size: 2.5rem;
  font-family: 'Inter',sans-serif;
}
.homebox p{
  /* font-weight: bold; */
  /* padding: 1rem 0rem; */
  font-size: 16px;
  /* font-family: 'Inter',sans-serif; */
}
.homebox1{
  position: absolute;
  width: 18rem;
  top:40.8rem;
  z-index: 3;
  opacity: 0.8;
  display: none;

}
.homebox1 img{
 width: 100%;

}
.homebutton button{
  width: 8rem;
  border-radius: 12px;
  background-color: orange;
  padding: 0.6rem 0rem;
}


/* =========================== */
/* homesec1 */
/* =========================== */

.homesec1container{

}
.homesec1boxs{
display: flex;
width: 80%;
margin: auto;
justify-content: center;
}
.homesec1box{
  margin: 1rem;
  background-color: rgb(170, 161, 161);
  border-radius: 12px;
  
}

.homesec1boximg{

}

/* DATABASE = mongodb+srv://rohan8933:Rahulgautam8933@cluster0.1s3qk9i.mongodb.net/user?retryWrites=true&w=majority */




/* ========================== */
/* ourMission */
/* ========================== */




.homeourmissionbox1{
  width: 80%;
  margin: auto;
  text-align: center;
  padding-top: 2rem;
}
.homeourmissionbox1 h1{
  font-size: 1.5rem;
}
.homeourmissionbox1 p{
  font-size: 13px;
}

.homeourmissionboxs{
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.homeourmissionbox{
  border: 2px solid rgb(245, 239, 239);
  width: 19rem;
  height: 15rem;
  text-align: center;
  margin: 1rem;
}
.homeourmissionbox h1{
  font-size: 1.5rem;
  padding-bottom: 1rem;
 
}
.homeourmissionbox p{
  font-size: 1rem;
 
}
.homeourmissionimg {
  width:54px;
  /* height: 120px;  */
  margin: 1rem auto;
  
}
.homeourmissionimg img{
  max-width: 100%;
  filter: hue-rotate(45deg);
  
}



/* ============================ */
/* homeourproject */
/* ============================ */

.homeourprojectcontainer{

}
.homeprojectcontent{
  width: 80%;
  margin: 3rem auto;
}
.homeprojectcontent h1{
  font-size: 2.5rem;
  padding-bottom: 1rem;
}

.homeprojectimgboxs{
  width: 80%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.homeprojectimgbox{
  width: 33rem;
  /* border: 2px solid rgb(188, 178, 178); */
  position: relative;
  margin: 1rem;
}
.homeprojectimgbox h2{
  padding: 1rem;


}
.homeprojectimg{
  width: 100%;
  height: 12rem;
  background-color: blue;
  border-radius: 12px ;
  overflow: hidden;
  box-shadow:  1px 1px 32px 1px #c8c8d7;
}
.homeprojectimg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* box-shadow: 1px 1px 12px 1px blue; */
}
.homeprojectimg img:hover{
  transform: scale(1.1);
  transition: all 2s;
}





/* ============================= */
/* contact us */
/* ============================= */

.contactcontainer{

}

.contactupper{

}
.contactupperbox{
  width: 27rem;
    height: 18rem;
    /* background-color: blue; */
    /* margin: 0rem 9rem; */
    /* margin-left: 13rem; */
    box-shadow: 1px 1px 30px 1px blue;
    margin-left: 11rem;
}
.contactupperbox2 h1{
  margin: 4rem;
  font-size: 1.5rem;
}
.contactupperbox img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contactupperbox1{
  position: absolute;
  width: 27rem;
    height: 18rem;
    top: 18rem;
    left: 38rem;
    box-shadow: 1px 1px 30px 1px blue;
}

.contactdonatebutton{
  position: absolute;
  top: 26rem;
  left: 20rem;
}
.contactdonatebutton button{

      width: 8rem;
    border-radius: 12px;
    background-color: orange;
    padding: 0.6rem 0rem;
}

.contactupperbox2{
  width: 26rem;
}
.contactupperbox2 a{
  font-size: 2rem;
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.contactupperbox1 img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contactupperimg{
  display: flex;
  /* justify-content: center; */
  position: relative;
  width: 80%;
  margin: 5rem auto;
}


#contactformcontainer{
  display: flex;
  justify-content: space-around;
  width: 80%;
  margin:  auto;
  margin-top: 3rem;
  /* margin-top: 30rem; */
  padding-top: 1rem;
  flex-wrap: wrap;
}

.contactbox h1{
  padding-bottom: 1rem;

}
.contactbox {
  margin-bottom: 0rem;

}
.contactbox p i {
  font-size: 2rem;
  color: blue;

}

.forminput input{
  width: 19rem;
    font-size: 1.3rem;
    padding: 6px;
    margin: 0.5rem 0px;
    border: none;
    border-bottom: 1px solid #b4a4a4;
}

.formtextarea textarea{
  width: 19rem;
  height: 4rem;
    font-size: 1.3rem;
    padding: 6px;
    margin: 4px 0px;
    border: none;
    border-bottom: 1px solid #b4a4a4;
}

.formbutton button{
  padding: 0.8rem;
  background-color: blue;
  color: white;
  border-radius: 4px;
  margin-bottom: 3rem;
}

.contactbox iframe{
  width: 18rem;
  height: 18rem;
  text-align: center;

}
.contactbox1{
  border: 2px solid orange;
  border-radius: 12px;
  padding: 1rem;
  margin: 2rem;
}

.contacelomks a{
  text-decoration: none;
  color: black;
}
.contacelomks a i {
  font-size: 1.3rem;
  padding: 0.3rem;
}






/* ============================ */
/* about */
/* ============================ */


#more {display: none;}

.aboutsec1container{

}


/* aboutsec1 */

.aboutuppersec1boxs{
  display: flex;
  flex-wrap: wrap;
   background-image: linear-gradient(180deg, White 16% ,orange);
 
}

.aboutuppersec1box{
  width: 100%;
  /* margin: 0.2rem; */
  /* height: 33.5rem; */
  /* background-color: blue; */
  /* background-image: linear-gradient(180deg, White ,orange); */
  /* background-color: orange; */
  overflow: hidden;
  position: relative;
}

.pageupperheading1{
  text-align: center;
  padding-top: 3rem;
}
.aboutuppersec1box h1{
  color: black;
    /* font-family: 'Architects Daughter', cursive; */
    /* font-family: 'Cookie', cursive; */
    /* font-family: 'Fira Sans', sans-serif; */
    /* font-family: 'Libre Baskerville', serif; */
    font-size: 1.3rem;
    /* font-family: cursive; */
    padding: 2rem;
    text-align: center;
    font-weight: bolder;
    /* margin-top: 5rem; */
    /* background: rgb(131,58,168); */
    /* background-image: linear-gradient(110deg,rgb(171 5 237) ,rgb(20 26 27)); */
    /* background-clip: text; */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */

}

.homebutton2 {
 
  text-align: center;
}
.homebutton2 button{
  background-color: orange;
  padding: 0.6rem;
  border-radius: 12px;
  text-align: center;
  color: white;
  box-shadow: 1px 1px 30px 1px blue;
  margin-bottom: 3rem;
}
.homebutton2 button:hover{
  background-color: rgb(97, 97, 172);
}

.aboutimgbox3{
  width: 70%;
  height: 9rem;
  /* margin-top: 5rem; */
  margin: auto;
  margin-bottom: 2rem;
  margin-top: 1rem;

}
.aboutimgbox3 img{
  width: 100%;
  height: 100%;
}

.aboutuppersec1box img:hover{
  transform: scale(1.1);
  transition: all 2s;
}




.aboutsec1boxs{
display: flex;
padding-top: 2rem;
justify-content: space-between;
width: 80%;
margin: auto;
flex-wrap: wrap-reverse;
}

.aboutsecbox{
width: 40rem;
/* height: 15rem; */
/* padding: 2rem; */
overflow: auto;
}

.aboutsecbox img{
width: 100%;
height: 100%;

}
.aboutimgbox1{
  /* overflow: scroll; */
  margin-top: 1.4rem;
}

.aboutbox12{
  overflow: scroll;
}
.aboutsecbox img:hover{
  transform: scale(1.1);
  transition: all 2s;

}
.aboutsecbox button{
  padding: 0.4rem;
  background-color: blue;
  color: white;
  margin-top: 1rem;
}

.aboutcards{
  display: flex;
  flex-direction: row;
  position: relative;
  background-image: linear-gradient(120deg,rgba(233, 138, 69, 0.9) 50% , transparent 50%),url('https://insidearabia.com/wp-content/uploads/2020/05/IMG_2393.jpg');
}
.aboutcardcontent{
  padding: 12px;
  width: 33rem;
}
.aboutcardcontent h1{
  font-size: 12px;
  width: 10rem;
}
.aboutcardcontent p{
  font-size: 10px;
  width: 10rem;
}
.cardimg1{
  width: 90rem;
  height: 23rem;
}
.cardimg1 img{
  width: 100%;
  height: 100%;
}

.arro{
  width: 50%;
    margin: auto;
    text-align: center;
    margin-top: -2rem;

}


/* Aboutourmission */

.aboutmissionboxs{
  width: 80%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  text-align: center;
  justify-content: center;
  margin-top: 3rem;
  

}
.aboutmissionbox{
  width: 100%;
  margin: 0.6rem;
  padding: 0.7rem;
  box-shadow: 1px 1px 30px 1px rgb(67, 221, 28);
  border: 1px solid rgb(244, 50, 50);
}

.aboutmissionbox1{
  width: 79%;
  margin: 0.6rem auto;
  padding: 0.7rem;
  box-shadow: 1px 1px 30px 1px rgb(80, 219, 45);
  border: 1px solid rgb(164, 157, 157);
  text-align: center;
}
.box{
  width: 88%;
  margin: auto;
  height: 15rem;
}




/* new gallary */

.gallarybox12{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 1rem; */
}
.piccontainer{
  width: 23rem;
  /* display: flex; */
  margin: 1rem;
  height: 18rem;
  
}
.piccontainer img{
  width: 100%;
  height: 100%;

}



  .model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    background-color:transparent;
    display: none;
    text-align: center;
    /* display: flex; */
    /* position: relative; */
  }

  .model::after{
    background: rgba(8, 6, 19, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
 
.modelimgcontainer{
    width: 100%;
    /* height: 100vh; */
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding:13rem  3rem;
}
.modelimgcontainer img{
  width: 100%;
  height: 100%;
  object-fit: cover;

}

  .open{
    display: block;
  }

  .modelimgcontainer h1 i{
    color: white;
  }







/* ====================================== */
/* gallary */
/* ====================================== */


.gallarysection2{
  width: 90%;
  margin: auto;
}

.videoclipcontainer{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.videoclip1{
  position: relative;
  margin: 1rem;
  /* width:rem; */
}
.videoclip1 iframe{
  position: relative;
  /* margin: 1rem; */
  width:20rem;
}

.gallarysection3{
  margin: auto;
  width: 90%;
}

.gallarysec3cards{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  text-align: center;
}

.gallarysec3card {
  width: 24.1rem;
  height:10rem;
  margin: 1rem;
  background-color: blue;
  box-shadow: 0px 0px 70px 0px rgb(185, 63, 226);
  overflow: hidden;
  border-radius: 17px;
}

.gallarysec3card img{

  width: 100%;
  height: 100%;
  object-fit: cover;
}



/* more media content */


.moremediacontainer{
  margin-top: 3rem;
}





/* ====================================== */
/* OurCauses */
/* ====================================== */

.causesbox2{

}
.causesboxs{
  padding-top: 3rem;
  margin: auto;
  width: 80%;

}
.causesbox1{
  width: 80%;
  margin: auto;
  text-align: center;
}
.causescards{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}
.causescard{
  width: 22rem;
  height: 22rem;
  margin: 1rem;
  box-shadow: 1px 1px 34px 1px orange;

  background-color:#f9e2e2;

}
.causescard h3{
  padding: 1rem;

}
.causescardimg{
  width: 17rem;
  height: 14rem;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

}
.causescardimg img{
  width: 100%;
  height: 100%;
  /* object-fit: cover; */

}
.causescardimg img:hover{
 transform: scale(1.1);
 transition: all 2s;

}

.pageupperheading{
  text-align: center;
  background-color: orange;
}
.pageupperheading h1{
  margin: 0;
}














/* donation */


.donationboxcontainer{
  width: 80%;
  margin: 2rem auto;
}


.donationboxs{
  display: flex;
  flex-wrap: wrap;
  /* background-color: blue; */
}
.donationbox1{
  background-color: #d2301c;
  padding: 1rem;
  color: white;
}

.donationbox2{
border: 1px solid black;
padding: 2rem;

}
.homesec4rasegoal{
  display: flex;
  justify-content: space-between;
}

.donationpaymentboxs1{
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.donationpaymentboxs1 p{
  margin: 0;
}

.donationinput{
  display: flex;
}
.donationinput p{
  text-align: center;
    background-color: blue;
    height: 2.4rem;
    color: white;
    width: 2rem;
    font-size: 17px;
    padding: 0.4rem;
    font-weight: bold;

}

.homesec4rasegoal{
  display: flex;
  justify-content: space-between;
}

.donationinput input{
  width: 4rem;
  height: 2.4rem;
}



.donationpaymentboxs2{
  display: flex;
  justify-content: center;
  /* padding: 12px; */
  flex-wrap: wrap;
}

.donationpaymentbox3{

  width: 4rem;
    height: 4rem;
    margin: 5px;
    font-size: 20px;
    padding-top: 1rem;
    text-align: center;
    justify-content: center;
  background-color: #9186d4;
  border-radius: 10px;
}
.donationpaymentbox3 p{
 font-size: 16px;
}

#donationpaymentbox3 p{
  margin: -14px;
}
.donationpaymentbox3:hover{

 
  background-color: blue;
}



.donationpaymentbutton button{
  width: 100%;
  height: 4rem;
  font-size: 33px;
  /* font-weight: bold; */
  margin-top: 2rem;
  background-color: #4cf41a;
  border: none;
}



.homesec4donations{
  padding-bottom: 1rem;
  color: black;
}



.containers{
  background-color: #ddd;
  border-radius: 12px;
  color: #000;
  height: 19px;
  margin: 0 auto;
  padding: 0;
  width: 83%;
}
  
.skills {
  color: black;
  height: 19px;
  text-align: right; /* Right-align text */
  padding-top: 10px; /* Add top padding */
  padding-bottom: 10px; /* Add bottom padding */
  color: white; /* White text color */
  border-radius: 12px;
  /* width: 83%; */
}
  
.html {
  width: 83%; background-color: #04AA6D;
  color: black;
  font-weight: bold;
} 








  /* payment model */


  .modalcontainer {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(189, 189, 189, 0.9);
    z-index: 2;
  }

  .modelcontent {
    overflow: scroll;
    z-index: 200;
    position: fixed;
    top: 50%;
    left: 50%;
    height: 100vh;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
  }


  .paymentupperpart {
    display: flex;

    margin: 2rem;
  }

  .payementvalue {
    background-color: blue;

  }

  .payementvalue p {
    padding: 1rem;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;

  }

  .paymentinputpart input {
    width: 12rem;
    font-size: 20px;
    padding: 12px;
  }

  .paymentupperpart2 {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem;
    text-align: center;
    justify-content: center;
  }

  .payementboxs {
    width: 67px;
    padding: 1rem;
    background-color: yellow;
    margin: 5px;
    text-align: center;
  }

  .payementboxs p {
    margin: 0;
  }

  .payementboxs:hover {
    background-color: blue;
    color: white;
  }

  #payementboxs {
    padding: 0;
  }


  .paymentupperpart3 {
    margin: 2rem;
  }

  .paymentmethodlinks {
    display: flex;
    flex-wrap: wrap;
  }

  .paymentmethodlinks p a {
    text-decoration: none;
    padding: 12px;
    color: black;
  }



  .paymentmidform {
    margin: 2rem;
  }

  .paymentformde {
    display: flex;
    flex-wrap: wrap;
    /* margin: 1rem; */
  }

  .paymentforminner {
    margin: 0.4rem 0rem;
  }

  .paymentinput1 input {
    width: 14rem;
    font-size: 1.2rem;
  }

  .paymentemail {
    margin: 0.4rem 0rem;
  }

  .paymentemailinput input {
    font-size: 1.2rem;
    width: 100%;

  }




  .paymentcheckbox {
    display: flex;
    justify-content: space-between;

    margin: 2rem;
  }

  .paymentcheckinpunt {
    display: flex;
  }

  .paymentcheckinpunt p {
    margin: 0;
  }



  .paymentdonatebutton {
    margin: 2rem;
  }

  .paymentdonatebutton button {
    width: 100%;
    background-color: blue;
    margin: auto;
  }

  .paymentdonatebutton button:hover {
    width: 100%;
    background-color: blue;
  }

  .paymentdonationtotal {
    display: flex;
    justify-content: center;
    text-align: center;

  }

  .paymentdonationtotal p {
    background-color: blue;
    padding: 12px;
    height: 56px;
    text-align: center;
    color: white;

  }

  .paymentdonationtotal input {

    /* padding: 0.4rem; */
    height: 56px;
    width: 9rem;


  }

  #paymentdonatebutton button {
    width: 80%;
    background-color: blue;
    text-align: center;
    color: white;
  }

  #paymentdonatebutton button:hover {

    background-color: rgb(67, 67, 220);
  }


  .closebutton {
    position: absolute;
    top: 45px;
    right: 12px;
  }

  .closebutton p i {
    font-size: 2.5rem;
    font-weight: bold;
  }












/* ======================== */
/* team */
/* ======================== */



.teamsecboxs{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 80%;
  margin: 2rem auto;
}
.teamsecboxs .teamsecbox{
  width: 266px;
    height: 300px;
    background-color: transparent;
    margin: 1rem;
    border: 2px solid thistle;
    border-radius: 25px;
    box-shadow: 0px 0px 98px 1px rgb(225, 222, 222);
}

.teamboximg{
  text-align: center;
    width: 150px;
    height: 150px;
    color: red;
    overflow: hidden;
    border-radius: 197px;
    margin: auto;
    margin-top: 2rem;
}
.teamboximg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.teamboxcontent{
  text-align: center;
  padding: 1.5rem 0rem;
}













/* ========================= */
/* footer */
/* ========================= */

.footercontainer{
/* background-image: linear-gradient(rgb(87, 87, 139),rgb(148, 130, 98)); */
background-image: linear-gradient(White,orange);
}


.footerlogo img{
  width: 9rem;
  box-shadow: 0px 0px 122px 0px #a5a5bd;
  margin-bottom: 1rem;
}

.footerboxs{
  display: flex;
  width: 80%;
  flex-wrap: wrap;
  margin: auto;
  padding: 0rem 0rem;
  justify-content: center;
}

.footerbox{
  margin: 1rem;
  width: 16.8rem;
  /* border: 2px solid black; */
}


.footeremailpara p{

  font-size: 1rem;

}

.footerbox p a{
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  margin: 0;
}
.footerbox p a:hover{
  color:rgb(19, 18, 18);
  /* background-color: black; */
  text-decoration: none;
  font-size: 1.2rem;
  
}
.footerbox p {
  /* font-size: 1.2rem; */
  margin: 0;
  
}

.footerbox h1{
  padding-bottom: 1rem;
}


.navlogolinks i{
      padding: 0.3rem;
    font-size: 1.7rem;
}
.navlogolinks a{
  text-decoration: none;
  color: black;
      
}







/* ............................................... */

.aboutimgbox1{
  position: relative;
}
.imghoverbutton1{
  background: rgb(0,0,0,.6);
    /* display: flex; */
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: .6s;
    width: 100%;
}
.imghoverbutton1:hover{
  
    opacity: 1;
    
}
.imghoverbutton1 button{
  
  /* position: absolute; */
  top: 36%;
  border-radius: 12px;
    background-color: orange;
    padding: 0.6rem 1rem;
    height: 3rem;
    margin-top: 4rem;
    
}
.imghoverbutton{
  background: rgb(0,0,0,.6);
    /* display: flex; */
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: .6s;
    width: 100%;
}
.imghoverbutton:hover{
  
    opacity: 1;
    
}
.imghoverbutton button{
  
  /* position: absolute; */
  top: 36%;
  border-radius: 12px;
    background-color: orange;
    padding: 0.6rem 1rem;
    height: 3rem;
    margin-top: 5rem;
    
}
















}


@media (min-width: 500px) and (max-width: 1000px){



  /* ================================ */
  /* Navbar */
  /* ================================ */
  *{
    margin: 0;
    padding: 0;
  }
  .navbarcontainer{
    background-color: transparent;
    position: relative;
    z-index: 99;
    /* box-shadow: 1px 1px 167px 1px orange; */
  
  }
  .navbarboxs{
  display: flex;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  }
  .navlogobackground {
    background-color: white;
      width: 6rem;
      height: 6rem;
      position: absolute;
      left: 30px;
      z-index: 23;
  
  }
  .navbarbox .navbarimgbox img{
    width: 5rem;
    /* padding: 1rem; */
    position: absolute;
    z-index: 30;
    margin-top: 1rem;
    /* box-shadow: 1px 1px 167px 1px orange; */
  
  
  }
  
  .menubutton i{
    font-size: 2.4rem;
    padding-top: 1.7rem;
    font-weight: bold;
    
  }
  .navbarbox ul{
    display: flex;
    padding-right: 10rem;
    display: none;
  
  }
  .navbarbox ul li{
    list-style: none;
    margin: 1rem;
  
  }
  .navbarbox ul li a{
    text-decoration: none;
    color: black;
    z-index: 99;
    font-weight: bold;
    font-size: 1.3rem;
  
  }
  
  
  
  
  
  
  
  
  
  /* toolbar */
  
  .toolbar{
    display: flex;
    justify-content: space-between;
    font-size: 30px;
    margin-left: 20px;
  margin-top: 10px;
  /* box-shadow: 1px 1px 1px 1px rgb(152, 152, 178); */
  }
  
  .imglogo img{
  width: 40px;
  margin-right: 17px;
  }
  .sidebar{
    overflow: hidden;
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100vh;
    background-color: black;
    color: white;
    width: 280px;
    transform:translateX(-100%) ;
    transition: all 0.4s;
    z-index: 10000;
  }
  
  
  .downbar{
    position: absolute;
    bottom: 0px;
    left: 0px;
    height: 40%;
    background-color: black;
    color: white;
    width: 100%;
    transform:translateY(0%) ;
    transition: all 0.4s;
  }
  .profile{
    display: flex;
    text-align: center;
    flex-direction: column;
    margin: 20px 80px;
    width: 100px;
  }
  
  .homelinks li{
  margin: 15px 30px;
  font-size: 17px;
  list-style: none;
  }
  .homelinks li a{
  color: white;
  text-decoration: none;
  }
  .homelinks li i{
  margin-right: 30px;
  }
  .profileimg img{
    width: 100px;
    height: 100px;
    border-radius: 73px;
  }
  
  
  
  .sidebaropen{
    transform:translateX(0%) ;
    
  }
  .downbaropen{
    transform:translateY(0%) ;
    
  }
  .backdrop{
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100vh;
    background-color: transparent;
    display: none;
    z-index: 999;
  }
  
  .backdropopen{
    display: block;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* ====================== */
  /* home */
  /* ====================== */
  
  .homecontaner{
    width: 100%;
    /* height: 100vh; */
    /* justify-content: center; */
    /* text-align: center; */
    display: flex;
    /* background-image: linear-gradient(45deg, rgb(224 218 207),rgb(208 202 202),rgb(209 209 215)); */
    background-image: linear-gradient(180deg, White 24%,orange);
  z-index: 2;
  margin-top: -5rem;
  }
  .homeboxs{
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: center;
  position: relative;
  padding-top: 9rem;
  padding-bottom: 5rem;
  /* flex-wrap: wrap; */
  
  }
  .homebox:first-child{
  margin-right: 0rem;
  
  }
  .homebox{
    width: 30rem;
    z-index: 5;
    color: black;
  
  }
  .homebox h2{
    font-weight: bolder;
    padding: 0.4rem 0rem;
    font-size: 1rem;
    margin-right: 2rem;
    /* font-family: 'Inter',sans-serif; */
  }
  .homebox h1{
    font-weight: bold;
    padding: 3rem 0rem;
    font-size: 2rem;
    font-family: 'Inter',sans-serif;
    margin-left: 2rem;
  }
  .homebox p{
    /* font-weight: bold; */
    /* padding: 1rem 0rem; */
    font-size: 10px;
    /* font-family: 'Inter',sans-serif; */
    margin-right: 3rem;
  }
  .homebox1{
    position: absolute;
    width: 11rem;
    top:7.8rem;
    z-index: 3;
    opacity: 0.8;
    /* display: none; */
  
  }
  .homebox1 img{
   width: 100%;
  
  }
  .homebutton button{
    /* width: 8rem; */
    border-radius: 12px;
    background-color: orange;
    padding: 0.3rem ;
  }
  
  
  /* =========================== */
  /* homesec1 */
  /* =========================== */
  
  .homesec1container{
  
  }
  .homesec1boxs{
  display: flex;
  width: 80%;
  margin: auto;
  justify-content: center;
  }
  .homesec1box{
    margin: 1rem;
    background-color: rgb(170, 161, 161);
    border-radius: 12px;
    
  }
  
  .homesec1boximg{
  
  }
  
  
  
  
  
  
  /* ========================== */
  /* ourMission */
  /* ========================== */
  
  
  .homeourmissioncontainer{
  
  }
  .homeourmissionbox1{
    width: 80%;
    margin: auto;
    text-align: center;
    padding-top: 2rem;
  }
  .homeourmissionbox1 h1{
    font-size: 1.5rem;
  }
  .homeourmissionbox1 p{
    font-size: 13px;
  }
  
  .homeourmissionboxs{
    width: 90%;
    margin: auto;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }
  
  .homeourmissionbox{
    border: 2px solid rgb(245, 239, 239);
    width: 14rem;
    height: 15rem;
    text-align: center;
    margin: 1rem;
  }
  .homeourmissionbox h1{
    font-size: 1.5rem;
    padding-bottom: 1rem;
   
  }
  .homeourmissionbox p{
    font-size: 1rem;
   
  }
  .homeourmissionimg {
    width:54px;
    /* height: 120px;  */
    margin: 1rem auto;
    
  }
  .homeourmissionimg img{
    max-width: 100%;
    filter: hue-rotate(45deg);
    
  }
  

  .homeourmissionbox:hover{
    transform: scale(1.1);
        transition: all 2s;
    
  }
  
  
  /* ============================ */
  /* homeourproject */
  /* ============================ */
  
  .homeourprojectcontainer{
  
  }
  .homeprojectcontent{
    width: 80%;
    margin: 3rem auto;
  }
  .homeprojectcontent h1{
    font-size: 2.5rem;
    padding-bottom: 1rem;
  }
  
  .homeprojectimgboxs{
    width: 80%;
    margin: auto;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  }
  .homeprojectimgbox{
    width: 33rem;
    /* border: 2px solid rgb(188, 178, 178); */
    position: relative;
    margin: 1rem;
  }
  .homeprojectimgbox h2{
    padding: 1rem;
  
  
  }
  .homeprojectimg{
    width: 100%;
    height: 17rem;
    background-color: blue;
    border-radius: 12px ;
    overflow: hidden;
    box-shadow:  1px 1px 32px 1px #c8c8d7;
  }
  .homeprojectimg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
    /* box-shadow: 1px 1px 12px 1px blue; */
  }
  .homeprojectimg img:hover{
    transform: scale(1.1);
    transition: all 2s;
  }
  
  
  
  
  
  /* ============================= */
  /* contact us */
  /* ============================= */
  
  .contactcontainer{
  
  }
  
  .contactupper{
  
  }
  .contactupperbox{
    width: 27rem;
      height: 18rem;
      /* background-color: blue; */
      /* margin: 0rem 9rem; */
      /* margin-left: 13rem; */
      box-shadow: 1px 1px 30px 1px blue;
      margin-left: 11rem;
  }
  .contactupperbox2 h1{
    margin: 4rem;
    font-size: 1.5rem;
  }
  .contactupperbox img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .contactupperbox1{
    position: absolute;
    width: 27rem;
      height: 18rem;
      top: 18rem;
      left: 38rem;
      box-shadow: 1px 1px 30px 1px blue;
  }
  
  .contactdonatebutton{
    position: absolute;
    top: 26rem;
    left: 20rem;
  }
  .contactdonatebutton button{
  
        width: 8rem;
      border-radius: 12px;
      background-color: orange;
      padding: 0.6rem 0rem;
  }
  
  .contactupperbox2{
    width: 26rem;
  }
  .contactupperbox2 a{
    font-size: 2rem;
    text-decoration: none;
    color: black;
    font-weight: bold;
  }
  .contactupperbox1 img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  .contactupperimg{
    display: flex;
    /* justify-content: center; */
    position: relative;
    width: 80%;
    margin: 5rem auto;
  }
  
  
  #contactformcontainer{
    display: flex;
    justify-content: space-around;
    width: 90%;
    margin:  auto;
    margin-top: 3rem;
    /* margin-top: 30rem; */
    padding-top: 1rem;
    flex-wrap: wrap;
  }
  
  .contactbox h1{
    padding-bottom: 1rem;
  
  }

  .contacelomks a{
  text-decoration: none;
  color: black;
}
.contacelomks a i {
  font-size: 1.3rem;
  padding: 0.3rem;
}


  
  .forminput input{
    width: 19rem;
      font-size: 1.3rem;
      padding: 6px;
      margin: 2rem 0px;
      border: none;
      border-bottom: 1px solid #b4a4a4;
  }
  
  .formtextarea textarea{
    width: 19rem;
    height: 4rem;
      font-size: 1.3rem;
      padding: 6px;
      margin: 4px 0px;
      border: none;
      border-bottom: 1px solid #b4a4a4;
  }
  
  .formbutton button{
    padding: 0.8rem;
    background-color: blue;
    color: white;
    border-radius: 4px;
  }



  
  
  
  
  /* ============================ */
  /* about */
  /* ============================ */
  
  
  #more {display: none;}
  
  .aboutsec1container{
  
  }
  
  
  /* aboutsec1 */
  
  .aboutuppersec1boxs{
    display: flex;
  }
  
  .aboutuppersec1box{
    width: 100%;
    /* margin: 0.2rem; */
    height: 26.5rem;
    /* background-color: blue; */
    background-image: linear-gradient(180deg, White ,orange);
    /* background-color: orange; */
    overflow: hidden;
    position: relative;
  }
  
  .pageupperheading1{
    text-align: center;
  }
  .aboutuppersec1box h1{
    /* color: cornsilk; */
    color: black;
      /* font-family: 'Architects Daughter', cursive; */
      /* font-family: 'Cookie', cursive; */
      /* font-family: 'Fira Sans', sans-serif; */
      /* font-family: 'Libre Baskerville', serif; */
      font-size: 0.9rem;
      /* font-family: cursive; */
      padding: 0.9rem;
      text-align: center;
      font-weight: bolder;
      margin-top: 5rem;
      /* background: rgb(131,58,168); */
      /* background-image: linear-gradient(110deg,rgb(171 5 237) ,rgb(20 26 27)); */
      /* background-clip: text; */
      /* -webkit-background-clip: text; */
      /* -webkit-text-fill-color: transparent; */
  
  }
  
  .homebutton2 {
   
    text-align: center;
  }
  .homebutton2 button{
    background-color: orange;
    padding: 0.6rem;
    border-radius: 12px;
    text-align: center;
    color: white;
    box-shadow: 1px 1px 30px 1px blue;
  }
  .homebutton2 button:hover{
    background-color: rgb(97, 97, 172);
  }
  
  .aboutimgbox3{
    width: 100%;
    height: 11rem;
    margin-top: 5rem;
  }
  .aboutimgbox3 img{
    width: 100%;
    height: 100%;
  }
  
  .aboutuppersec1box img:hover{
    transform: scale(1.1);
    transition: all 2s;
  }
  
  
  
  
  .aboutsec1boxs{
  display: flex;
  padding-top: 2rem;
  justify-content: space-between;
  width: 80%;
  margin: auto;
  flex-wrap: wrap;
  }
  
  .aboutsecbox{
  width: 40rem;
  height: 22rem;
  /* padding: 2rem; */
  overflow: auto;
  }
  
  .aboutsecbox img{
  width: 100%;
  height: 100%;
  
  }
  .aboutimgbox1{
    overflow: hidden;
  }
  .aboutsecbox img:hover{
    transform: scale(1.1);
    transition: all 2s;
  
  }
  .aboutsecbox button{
    padding: 0.4rem;
    background-color: blue;
    color: white;
    margin-top: 1rem;
  }
  
  .aboutcards{
    display: flex;
    flex-direction: row;
    position: relative;
    background-image: linear-gradient(120deg,rgba(233, 138, 69, 0.9) 50% , transparent 50%),url('https://insidearabia.com/wp-content/uploads/2020/05/IMG_2393.jpg');
  }
  .aboutcardcontent{
    padding: 2rem;
    width: 33rem;
  }
  .cardimg1{
    width: 90rem;
    height: 23rem;
  }
  .cardimg1 img{
    width: 100%;
    height: 100%;
  }
  
  .arro{
    width: 50%;
      margin: auto;
      text-align: center;
      margin-top: 2rem;
  
  }
  
  
  /* Aboutourmission */
  
  .aboutmissionboxs{
    width: 80%;
    margin: auto;
    display: flex;
    /* flex-wrap: wrap; */
    text-align: center;
    justify-content: center;
    
  
  }
  .aboutmissionbox{
    width: 100%;
    margin: 0.6rem;
    padding: 0.7rem;
    box-shadow: 1px 1px 30px 1px black;
    border: 1px solid rgb(164, 157, 157);
    font-size: 15px;
    }
  
  .aboutmissionbox1{
    width: 79%;
    margin: 0.6rem auto;
    padding: 0.7rem;
    box-shadow: 1px 1px 30px 1px black;
    border: 1px solid rgb(164, 157, 157);
    text-align: center;
  }
  .box{
    width: 93%;
    margin: auto;
  }
  
  
  
  
  
  
  
  
  
  
  /* ====================================== */
  /* OurCauses */
  /* ====================================== */
  
  .causesbox2{
  
  }
  .causesboxs{
    padding-top: 3rem;
    margin: auto;
    width: 80%;
  
  }
  .causesbox1{
    width: 80%;
    margin: auto;
    text-align: center;
  }
  .causescards{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
  
  }
  .causescard{
    width: 22rem;
    height: 22rem;
    margin: 1rem;
    box-shadow: 1px 1px 34px 1px orange;
  
    background-color:#f9e2e2;
  
  }
  .causescard h3{
    padding: 1rem;
  
  }
  .causescardimg{
    width: 22rem;
    height: 14rem;
    border-radius: 12px;
    overflow: hidden;
    position: relative;
  
  }
  .causescardimg img{
    width: 100%;
    height: 100%;
    /* object-fit: cover; */
  
  }
  .causescardimg img:hover{
   transform: scale(1.1);
   transition: all 2s;
  
  }
  
  .pageupperheading{
    text-align: center;
    background-color: orange;
  }
  .pageupperheading h1{
    margin: 0;
  }
  
  




/* new gallary */

.gallarybox12{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 1rem; */
}
.piccontainer{
  width: 29%;
  /* display: flex; */
  margin: 1rem;
  /* height: 18rem; */
  
}
.piccontainer img{
  width: 100%;
  height: 100%;

}



  .model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    background-color:transparent;
    display: none;
    text-align: center;
    /* display: flex; */
    /* position: relative; */
  }

  .model::after{
    background: rgba(8, 6, 19, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
 
.modelimgcontainer{
    width: 100%;
    /* height: 100vh; */
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding:7rem 3rem;
}
.modelimgcontainer img{
  width: 100%;
  height: 100%;
  object-fit: cover;

}

  .open{
    display: block;
  }

  .modelimgcontainer h1 i{
    color: white;
  }




    
    
    
    /* ====================================== */
    /* gallary */
    /* ====================================== */
    
    
    
    
    .gallarysection2{
      width: 80%;
      margin: auto;
    
    }
    
    .videoclipcontainer{
      display: flex;
      justify-content: center;
      text-align: center;
    }
    
    .videoclip1{
      position: relative;
      width: 260px;
      height: 169px;
      background-color: rgb(171, 171, 189);
      margin: 1rem;
    }
    .videoclip1 iframe{
      position: relative;
      width: 260px;
      height: 169px;
      
    }
    
    .gallarysection3{
      margin: auto;
      width: 90%;
    }
    
    .gallarysec3cards{
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .gallarysec3card {
      width: 10.1rem;
      height:6rem;
      margin: 1rem;
      background-color: blue;
    }
    .gallarysec3card img{
      /* width: 23.6rem; */
      /* height:18rem; */
      /* margin: 1rem; */
    
    
        width: 100%;
        height: 100%;
        /* margin: 1rem; */
        /* max-width: 100%; */
        /* max-height: 100%; */
        object-fit: cover;
      
    }
    
    
    /* more media content */


.moremediacontainer{
  margin-top: 8rem;
}







  
  
  
  
  
  
  
  
  
  
  
  
  /* donation */
  
  
  .donationboxcontainer{
    width: 80%;
    margin: 2rem auto;
  }
  
  
  .donationboxs{
    display: flex;
    flex-wrap: wrap;
    /* background-color: blue; */
  }
  .donationbox1{
    background-color: #d2301c;
    padding: 1rem;
    color: white;
  }
  
  .donationbox2{
  border: 1px solid black;
  padding: 2.4rem;
  
  }
  
  .donationpaymentboxs1{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }
  .donationpaymentboxs1 p{
    margin: 0;
  }
  
  .donationinput{
    display: flex;
  }
  .donationinput p{
    text-align: center;
      background-color: blue;
      height: 4rem;
      color: white;
      width: 2rem;
      font-size: 30px;
      padding: 0.4rem;
      font-weight: bold;
  
  }
  .donationinput input{
    width: 6rem;
    height: 4rem;
  }
  
  
  
  .donationpaymentboxs2{
    display: flex;
    justify-content: center;
    /* padding: 12px; */
    flex-wrap: wrap;
  }
  
  .donationpaymentbox3{
  
    width: 4rem;
      height: 4rem;
      margin: 5px;
      font-size: 20px;
      padding-top: 1rem;
      text-align: center;
      justify-content: center;
    background-color: #9186d4;
    border-radius: 21px 0px 21px 0px;
  }
  .donationpaymentbox3 p{
   font-size: 16px;
  }
  
  #donationpaymentbox3 p{
    margin: -14px;
  }
  .donationpaymentbox3:hover{
  
   
    background-color: blue;
  }
  
  
  
  .donationpaymentbutton button{
    width: 100%;
    height: 4rem;
    font-size: 33px;
    /* font-weight: bold; */
    margin-top: 2rem;
    background-color: #4cf41a;
    border: none;
    border-radius: 58px 7px 62px 7px;
  }
  
  
  
  .homesec4donations{
    padding-bottom: 1rem;
    color: black;
  }
  
  
  
  .containers{
    background-color: #ddd;
    border-radius: 12px;
    color: #000;
    height: 19px;
    margin: 0 auto;
    padding: 0;
    width: 83%;
  }
    
  .skills {
    color: black;
    height: 19px;
    text-align: right; /* Right-align text */
    padding-top: 10px; /* Add top padding */
    padding-bottom: 10px; /* Add bottom padding */
    color: white; /* White text color */
    border-radius: 12px;
    /* width: 83%; */
  }
    
  .html {
    width: 83%; background-color: #04AA6D;
    color: black;
    font-weight: bold;
  } 
  
  
  
  
  
  
  
  
  
  /* payment model */


  .modalcontainer {
    position: fixed;
    overflow-y: scroll;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    height: 100vh;
    background-color: rgba(189, 189, 189, 0.9);
    z-index: 2;
  }

  .modelcontent {
    overflow: scroll;
    z-index: 200;
    position: fixed;
    top: 50%;
    left: 50%;
    height: 100vh;
    transform: translate(-50%, -50%);
    background-color: white;
    color: black;
  }


  .paymentupperpart {
    display: flex;

    margin: 2rem;
  }

  .payementvalue {
    background-color: blue;

  }

  .payementvalue p {
    padding: 1rem;
    font-size: 1rem;
    font-weight: bold;
    margin: 0;

  }

  .paymentinputpart input {
    width: 12rem;
    font-size: 20px;
    padding: 12px;
  }

  .paymentupperpart2 {
    display: flex;
    flex-wrap: wrap;
    margin: 2rem;
    text-align: center;
    justify-content: center;
  }

  .payementboxs {
    width: 67px;
    padding: 1rem;
    background-color: yellow;
    margin: 5px;
    text-align: center;
  }

  .payementboxs p {
    margin: 0;
  }

  .payementboxs:hover {
    background-color: blue;
    color: white;
  }

  #payementboxs {
    padding: 0;
  }


  .paymentupperpart3 {
    margin: 2rem;
  }

  .paymentmethodlinks {
    display: flex;
    flex-wrap: wrap;
  }

  .paymentmethodlinks p a {
    text-decoration: none;
    padding: 12px;
    color: black;
  }



  .paymentmidform {
    margin: 2rem;
  }

  .paymentformde {
    display: flex;
    flex-wrap: wrap;
    /* margin: 1rem; */
  }

  .paymentforminner {
    margin: 0.4rem 0rem;
  }

  .paymentinput1 input {
    width: 14rem;
    font-size: 1.2rem;
  }

  .paymentemail {
    margin: 0.4rem 0rem;
  }

  .paymentemailinput input {
    font-size: 1.2rem;
    width: 100%;

  }




  .paymentcheckbox {
    display: flex;
    justify-content: space-between;

    margin: 2rem;
  }

  .paymentcheckinpunt {
    display: flex;
  }

  .paymentcheckinpunt p {
    margin: 0;
  }



  .paymentdonatebutton {
    margin: 2rem;
  }

  .paymentdonatebutton button {
    width: 100%;
    background-color: blue;
    margin: auto;
  }

  .paymentdonatebutton button:hover {
    width: 100%;
    background-color: blue;
  }

  .paymentdonationtotal {
    display: flex;
    justify-content: center;
    text-align: center;

  }

  .paymentdonationtotal p {
    background-color: blue;
    padding: 12px;
    height: 56px;
    text-align: center;
    color: white;

  }

  .paymentdonationtotal input {

    /* padding: 0.4rem; */
    height: 56px;
    width: 9rem;


  }

  #paymentdonatebutton button {
    width: 80%;
    background-color: blue;
    text-align: center;
    color: white;
  }

  #paymentdonatebutton button:hover {

    background-color: rgb(67, 67, 220);
  }


  .closebutton {
    position: absolute;
    top: 45px;
    right: 12px;
  }

  .closebutton p i {
    font-size: 2.5rem;
    font-weight: bold;
  }






  
  
  
  
  
  /* ======================== */
  /* team */
  /* ======================== */
  
  
  
  .teamsecboxs{
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    width: 80%;
    margin: 2rem auto;
  }
  .teamsecboxs .teamsecbox{
    width: 266px;
      height: 300px;
      background-color: transparent;
      margin: 1rem;
      border: 2px solid thistle;
      border-radius: 25px;
      box-shadow: 0px 0px 98px 1px rgb(225, 222, 222);
  }
  
  .teamboximg{
    text-align: center;
      width: 150px;
      height: 150px;
      color: red;
      overflow: hidden;
      border-radius: 197px;
      margin: auto;
      margin-top: 2rem;
  }
  .teamboximg img{
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
  
  
  .teamboxcontent{
    text-align: center;
    padding: 1.5rem 0rem;
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  /* ========================= */
  /* footer */
  /* ========================= */
  
  .footercontainer{
  /* background-image: linear-gradient(rgb(87, 87, 139),rgb(148, 130, 98)); */
  background-image: linear-gradient(White,orange);
  }
  
  
  .footerlogo img{
    width: 9rem;
    box-shadow: 0px 0px 122px 0px #a5a5bd;
    margin-bottom: 1rem;
  }
  
  .footerboxs{
    display: flex;
    width: 80%;
    flex-wrap: wrap;
    margin: auto;
    padding: 3rem 0rem;
    justify-content: space-between;
  }
  
  .footerbox{
    margin: 1rem;
    width: 16.8rem;
    /* border: 2px solid black; */
  }
  .footerbox p a{
    color: black;
    text-decoration: none;
    font-size: 1.2rem;
    
  }
  .footerbox p a:hover{
    color:rgb(19, 18, 18);
    /* background-color: black; */
    text-decoration: none;
    font-size: 1.2rem;
    
  }
  .footerbox p {
    font-size: 1.2rem;
    
  }
  
  .footerbox h1{
    padding-bottom: 1rem;
  }
  
  
.navlogolinks i{
      padding: 0.3rem;
    font-size: 1.7rem;
}
.navlogolinks a{
  text-decoration: none;
  color: black;
      
}

  
  
  
  
  
  /* ............................................... */
  
  .aboutimgbox1{
    position: relative;
  }
  .imghoverbutton1{
    background: rgb(0,0,0,.6);
      /* display: flex; */
      height: 100%;
      justify-content: center;
      left: 0;
      opacity: 0;
      position: absolute;
      text-align: center;
      top: 0;
      transition: .6s;
      width: 100%;
  }
  .imghoverbutton1:hover{
    
      opacity: 1;
      
  }
  .imghoverbutton1 button{
    
    /* position: absolute; */
    top: 36%;
    border-radius: 12px;
      background-color: orange;
      padding: 0.6rem 1rem;
      height: 3rem;
      margin-top: 9rem;
      
  }
  .imghoverbutton{
    background: rgb(0,0,0,.6);
      /* display: flex; */
      height: 100%;
      justify-content: center;
      left: 0;
      opacity: 0;
      position: absolute;
      text-align: center;
      top: 0;
      transition: .6s;
      width: 100%;
  }
  .imghoverbutton:hover{
    
      opacity: 1;
      
  }
  .imghoverbutton button{
    
    /* position: absolute; */
    top: 36%;
    border-radius: 12px;
      background-color: orange;
      padding: 0.6rem 1rem;
      height: 3rem;
      margin-top: 5rem;
      
  }
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  
  }


  @media (min-width: 1000px) and (max-width:1180px){



    /* ================================ */
    /* Navbar */
    /* ================================ */
    *{
      margin: 0;
      padding: 0;
    }
    .navbarcontainer{
      background-color: transparent;
      position: relative;
      z-index: 99;
      /* box-shadow: 1px 1px 167px 1px orange; */
    
    }
    .navbarboxs{
    display: flex;
    justify-content: space-between;
    width: 80%;
    margin: auto;
    }
    
    
    .menubutton{
      display: none;
    }
    
    .navlogobackground {
      background-color: white;
        width: 11rem;
        height: 10rem;
        position: absolute;
        left: 134px;
        z-index: 23;
        display: none;
    
    }
    .navbarbox .navbarimgbox img{
      width: 7rem;
      /* padding: 1rem; */
      position: absolute;
      z-index: 30;
      margin-top: 1rem;
      /* box-shadow: 1px 1px 167px 1px orange; */
    
    
    }
    .navbarbox ul{
      display: flex;
      padding-right: 3rem;
    
    }
    .navbarbox ul li{
      list-style: none;
      margin: 1rem;
    
    }
    .navbarbox ul li a{
      text-decoration: none;
      color: black;
      z-index: 99;
      font-weight: bold;
      font-size: 1.1rem;
    
    }
    
    
    
    
    
    
    
    
    /* toolbar */
    
    .toolbar{
      display: flex;
      justify-content: space-between;
      font-size: 30px;
      margin-left: 20px;
    margin-top: 10px;
    /* box-shadow: 1px 1px 1px 1px rgb(152, 152, 178); */
    }
    
    .imglogo img{
    width: 40px;
    margin-right: 17px;
    }
    .sidebar{
      overflow: hidden;
      position: absolute;
      top: 0px;
      left: 0px;
      height: 100vh;
      background-color: black;
      color: white;
      width: 280px;
      transform:translateX(-100%) ;
      transition: all 0.4s;
      z-index: 1;
    }
    
    
    .downbar{
      position: absolute;
      bottom: 0px;
      left: 0px;
      height: 40%;
      background-color: black;
      color: white;
      width: 100%;
      transform:translateY(0%) ;
      transition: all 0.4s;
    }
    .profile{
      display: flex;
      text-align: center;
      flex-direction: column;
      margin: 20px 80px;
      width: 100px;
    }
    
    .homelinks li{
    margin: 15px 30px;
    font-size: 17px;
    list-style: none;
    }
    .homelinks li a{
    color: white;
    text-decoration: none;
    }
    .homelinks li i{
    margin-right: 30px;
    }
    .profileimg img{
      width: 100px;
      height: 100px;
      border-radius: 73px;
    }
    
    
    
    .sidebaropen{
      transform:translateX(0%) ;
      
    }
    .downbaropen{
      transform:translateY(0%) ;
      
    }
    .backdrop{
      position: fixed;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100vh;
      background-color: transparent;
      display: none;
    }
    
    .backdropopen{
      display: block;
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    /* ====================== */
    /* home */
    /* ====================== */
    
    .homecontaner{
      width: 100%;
      height: 47rem;
      /* justify-content: center; */
      /* text-align: center; */
      display: flex;
      /* background-image: linear-gradient(45deg, rgb(224 218 207),rgb(208 202 202),rgb(209 209 215)); */
      background-image: linear-gradient(180deg, White 25%,orange);
    z-index: 2;
    margin-top: -5rem;
    }
    .homeboxs{
    display: flex;
    width: 85%;
    margin: auto;
    justify-content: center;
    position: relative;
    padding-top: 7rem;
    padding-bottom: 5rem;
    
    }
    .homebox:first-child{
    margin-right: 18rem;
    
    }
    .homebox{
      width: 30rem;
      z-index: 5;
    
    }
    .homebox h2{
      font-weight: bold;
      padding: 1rem 0rem;
      font-size: 2rem;
      font-family: 'Inter',sans-serif;
    }
    .homebox h1{
      font-weight: bold;
      padding: 1rem 0rem;
      font-size: 3rem;
      font-family: 'Inter',sans-serif;
      margin-top: 4rem;
    }
    .homebox p{
      /* font-weight: bold; */
      /* padding: 1rem 0rem; */
      font-size: 16px;
      /* font-family: 'Inter',sans-serif; */
    }
    .homebox1{
      position: absolute;
      /* width: 300rem; */
      top:8.8rem;
      z-index: 3;
      opacity: 0.8;
    
    }
    
    .homebox1 {
      width: 16rem;
      height: 24rem;
    }
    .homebox1 img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .teamheading{
      width: 80%;
      margin: auto;
      margin-top: 1rem;
    }
    
    .homebutton button{
      width: 8rem;
      border-radius: 12px;
      background-color: orange;
      padding: 0.6rem 0rem;
    }
    
    
    /* =========================== */
    /* homesec1 */
    /* =========================== */
    
    .homesec1container{
    
    }
    .homesec1boxs{
    display: flex;
    width: 80%;
    margin: auto;
    justify-content: center;
    }
    .homesec1box{
      margin: 1rem;
      background-color: rgb(170, 161, 161);
      border-radius: 12px;
      
    }
    
    .homesec1boximg{
    
    }
    
    
    
    
    
    
    /* ========================== */
    /* ourMission */
    /* ========================== */
    
    
    .homeourmissioncontainer{
    
    }
    .homeourmissionbox1{
      width: 80%;
      margin: auto;
      text-align: center;
      padding: 2rem;
    }
    .homeourmissionbox1 h1{
      font-size: 2.5rem;
    }
    .homeourmissionbox1 p{
      font-size: 1.3rem;
    }
    
    .homeourmissionboxs{
      width: 90%;
      margin: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
    }
    
    .homeourmissionbox{
      border: 2px solid rgb(245, 239, 239);
      width: 19rem;
      height: 15rem;
      text-align: center;
      margin: 1rem;
    }
    .homeourmissionbox h1{
      font-size: 1.5rem;
      padding-bottom: 1rem;
     
    }
    .homeourmissionbox p{
      font-size: 1rem;
     
    }
    .homeourmissionimg {
      width:54px;
      /* height: 120px;  */
      margin: 1rem auto;
      
    }
    .homeourmissionimg img{
      max-width: 100%;
      filter: hue-rotate(45deg);
      
    }
    

    .homeourmissionbox:hover{
      transform: scale(1.1);
          transition: all 2s;
      
    }
    
    
    /* ============================ */
    /* homeourproject */
    /* ============================ */
    
    .homeourprojectcontainer{
    
    }
    .homeprojectcontent{
      width: 90%;
      margin: 3rem auto;
      text-align: center;
    }
    .homeprojectcontent h1{
      font-size: 2.5rem;
      padding-bottom: 1rem;
    }
    
    .homeprojectimgboxs{
      width: 90%;
      margin: auto;
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    }
    .homeprojectimgbox{
      width: 25rem;
      /* border: 2px solid rgb(188, 178, 178); */
      position: relative;
      margin: 1rem;
    }
    .homeprojectimgbox h2{
      padding: 1rem;
    
    
    }
    .homeprojectimg{
      width: 100%;
      height: 17rem;
      background-color: blue;
      border-radius: 12px ;
      overflow: hidden;
      box-shadow:  1px 1px 32px 1px #c8c8d7;
    }
    .homeprojectimg img{
      width: 100%;
      height: 100%;
      object-fit: cover;
      /* box-shadow: 1px 1px 12px 1px blue; */
    }
    .homeprojectimg img:hover{
      transform: scale(1.1);
      transition: all 2s;
    }
    
    
    
    
    
    /* ============================= */
    /* contact us */
    /* ============================= */
    
    .contactcontainer{
    
    }
    
    .contactupper{
    
    }
    .contactupperbox{
      width: 27rem;
        height: 18rem;
        /* background-color: blue; */
        /* margin: 0rem 9rem; */
        /* margin-left: 13rem; */
        box-shadow: 1px 1px 30px 1px blue;
        margin-left: 11rem;
    }
    .contactupperbox2 h1{
      margin: 4rem;
      font-size: 1.5rem;
    }
    .contactupperbox img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    .contactupperbox1{
      position: absolute;
      width: 27rem;
        height: 18rem;
        top: 18rem;
        left: 38rem;
        box-shadow: 1px 1px 30px 1px blue;
    }
    
    .contactdonatebutton{
      position: absolute;
      top: 26rem;
      left: 20rem;
    }
    .contactdonatebutton button{
    
          width: 8rem;
        border-radius: 12px;
        background-color: orange;
        padding: 0.6rem 0rem;
    }
    
    .contactupperbox2{
      width: 26rem;
    }
    .contactupperbox2 a{
      font-size: 2rem;
      text-decoration: none;
      color: black;
      font-weight: bold;
    }
    .contactupperbox1 img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }

    .contacelomks a{
  text-decoration: none;
  color: black;
}
.contacelomks a i {
  font-size: 1.3rem;
  padding: 0.3rem;
}


    .contactupperimg{
      display: flex;
      /* justify-content: center; */
      position: relative;
      width: 80%;
      margin: 5rem auto;
    }
    
    
    #contactformcontainer{
      display: flex;
      justify-content: space-around;
      width: 90%;
      margin:  auto;
      margin-top: 3rem;
      /* margin-top: 30rem; */
      padding-top: 1rem;
    }
    
    .contactbox h1{
      padding-bottom: 1rem;
    
    }
    .contactbox iframe{
      width: 18rem;
      height: 24rem;
    }
    
    .forminput input{
      width: 19rem;
        font-size: 1.3rem;
        padding: 6px;
        margin: 2rem 0px;
        border: none;
        border-bottom: 1px solid #b4a4a4;
    }
    
    .formtextarea textarea{
      width: 19rem;
      height: 4rem;
        font-size: 1.3rem;
        padding: 6px;
        margin: 4px 0px;
        border: none;
        border-bottom: 1px solid #b4a4a4;
    }
    
    .formbutton button{
      padding: 0.8rem;
      background-color: blue;
      color: white;
      border-radius: 4px;
    }
    
    
    
    
    /* ============================ */
    /* about */
    /* ============================ */
    
    
    #more {display: none;}
    
    .aboutsec1container{
    
    }
    
    
    /* aboutsec1 */
    
    .aboutuppersec1boxs{
      display: flex;
    }
    
    .aboutuppersec1box{
      width: 100%;
      /* margin: 0.2rem; */
      height: 33.5rem;
      /* background-color: blue; */
      background-image: linear-gradient(180deg, White 12% ,orange);
      /* background-color: orange; */
      overflow: hidden;
      position: relative;
    }
    
    .pageupperheading1{
      text-align: center;
    }
    .aboutuppersec1box h1{
      /* color: cornsilk; */
      color: black;
        /* font-family: 'Architects Daughter', cursive; */
        /* font-family: 'Cookie', cursive; */
        /* font-family: 'Fira Sans', sans-serif; */
        /* font-family: 'Libre Baskerville', serif; */
        font-size: 1.3rem;
        /* font-family: cursive; */
        padding: 2rem;
        text-align: center;
        font-weight: bolder;
        margin-top: 5rem;
        /* background: rgb(131,58,168); */
        /* background-image: linear-gradient(110deg,rgb(171 5 237) ,rgb(20 26 27)); */
        /* background-clip: text; */
        /* -webkit-background-clip: text; */
        /* -webkit-text-fill-color: transparent; */
    
    }
    
    .homebutton2 {
     
      text-align: center;
    }
    .homebutton2 button{
      background-color: orange;
      padding: 0.6rem;
      border-radius: 12px;
      text-align: center;
      color: white;
      box-shadow: 1px 1px 30px 1px blue;
    }
    .homebutton2 button:hover{
      background-color: rgb(97, 97, 172);
    }
    
    .aboutimgbox3{
      width: 100%;
      height: 15rem;
      margin-top: 5rem;
    }
    .aboutimgbox3 img{
      width: 100%;
      height: 100%;
    }
    
    .aboutuppersec1box img:hover{
      transform: scale(1.1);
      transition: all 2s;
    }
    
    
    
    
    .aboutsec1boxs{
    display: flex;
    padding-top: 2rem;
    justify-content: space-between;
    width: 90%;
    margin: auto;
    }
    
    .aboutsecbox{
    width: 40rem;
    height: 30rem;
    padding: 2rem;
    overflow: auto;
    }
    
    .aboutsecbox img{
    width: 100%;
    height: 100%;
    
    }
    .aboutimgbox1{
      overflow: hidden;
    }
    .aboutsecbox img:hover{
      transform: scale(1.1);
      transition: all 2s;
    
    }
    .aboutsecbox button{
      padding: 0.4rem;
      background-color: blue;
      color: white;
      margin-top: 1rem;
    }
    
    .aboutcards{
      display: flex;
      flex-direction: row;
      position: relative;
      background-image: linear-gradient(120deg,rgba(233, 138, 69, 0.9) 50% , transparent 50%),url('https://insidearabia.com/wp-content/uploads/2020/05/IMG_2393.jpg');
    }
    .aboutcardcontent{
      padding: 2rem;
      width: 33rem;
    }
    .cardimg1{
      width: 90rem;
      height: 23rem;
    }
    .cardimg1 img{
      width: 100%;
      height: 100%;
    }
    
    .arro{
      width: 50%;
        margin: auto;
        text-align: center;
        margin-top: 2rem;
    
    }
    
    
    /* Aboutourmission */
    
    .aboutmissionboxs{
      width: 90%;
      margin: auto;
      display: flex;
      /* flex-wrap: wrap; */
      text-align: center;
      justify-content: center;
      
    
    }
    .aboutmissionbox{
      width: 100%;
      margin: 0.6rem;
      padding: 0.7rem;
      box-shadow: 1px 1px 30px 1px black;
      border: 1px solid rgb(164, 157, 157);
    }
    
    .aboutmissionbox1{
      width: 88%;
      margin: 0.6rem auto;
      padding: 0.7rem;
      box-shadow: 1px 1px 30px 1px black;
      border: 1px solid rgb(164, 157, 157);
      text-align: center;
    }
    .box{
      width: 100%;
      margin: auto;
    }
    
    
    
    
    
    
    
    
    
    
    /* ====================================== */
    /* OurCauses */
    /* ====================================== */
    
    .causesbox2{
    
    }
    .causesboxs{
      padding-top: 3rem;
      margin: auto;
      width: 95%;
    
    }
    .causesbox1{
      width: 80%;
      margin: auto;
      text-align: center;
    }
    .causescards{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
    
    }
    .causescard{
      width: 17rem;
      height: 22rem;
      margin: 1rem;
      box-shadow: 1px 1px 34px 1px orange;
    
      background-color:#f9e2e2;
    
    }
    .causescard h3{
      padding: 1rem;
      font-size: 1.3rem;
    
    }
    .causescardimg{
      width: 100%;
      height: 14rem;
      border-radius: 12px;
      overflow: hidden;
      position: relative;
    
    }
    .causescardimg img{
      width: 100%;
      height: 100%;
      /* object-fit: cover; */
    
    }
    .causescardimg img:hover{
     transform: scale(1.1);
     transition: all 2s;
    
    }
    
    .pageupperheading{
      text-align: center;
      background-color: orange;
    }
    .pageupperheading h1{
      margin: 0;
    }
    
    
    
    
    

/* new gallary */

.gallarybox12{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 1rem; */
}
.piccontainer{
  width: 29%;
  /* display: flex; */
  margin: 1rem;
  /* height: 18rem; */
  
}
.piccontainer img{
  width: 100%;
  height: 100%;

}



  .model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    background-color:transparent;
    display: none;
    text-align: center;
    /* display: flex; */
    /* position: relative; */
  }

  .model::after{
    background: rgba(8, 6, 19, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
 
.modelimgcontainer{
    width: 60%;
    /* height: 100vh; */
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 3rem;
}
.modelimgcontainer img{
  width: 100%;
  height: 100%;
  object-fit: cover;

}

  .open{
    display: block;
  }

  .modelimgcontainer h1 i{
    color: white;
  }


    
    
    
    
    
    /* ====================================== */
    /* gallary */
    /* ====================================== */
    
    
    
    
    .gallarysection2{
      width: 80%;
      margin: auto;
    
    }
    
    .videoclipcontainer{
      display: flex;
      justify-content: center;
      text-align: center;
    }
    
    .videoclip1{
      position: relative;
      width: 580px;
      height: 400px;
      background-color: rgb(136, 136, 153);
      margin: 1rem;
    }
    .videoclip1 iframe{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    .gallarysection3{
      margin: auto;
      width: 90%;
    }
    
    .gallarysec3cards{
      display: flex;
      justify-content: center;
      text-align: center;
    }
    .gallarysec3card {
      width: 14.1rem;
      height:10rem;
      margin: 1rem;
      background-color: blue;
    }
    .gallarysec3card img{
      /* width: 23.6rem; */
      /* height:18rem; */
      /* margin: 1rem; */
    
    
        width: 100%;
        height: 100%;
        /* margin: 1rem; */
        /* max-width: 100%; */
        /* max-height: 100%; */
        object-fit: cover;
      
    }
    
    
    
    /* more media content */


.moremediacontainer{
  margin-top: 8rem;
}
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    
    /* donation */
    
    
    .donationboxcontainer{
      width: 90%;
      margin: 2rem auto;
    }
    
    
    .donationboxs{
      display: flex;
      /* background-color: blue; */
    }
    .donationbox1{
      background-color: #d2301c;
      padding: 4rem;
      color: white;
      margin: 0rem 1rem;
      border-radius: 0rem 0rem 0rem 20rem ;
    }
    
    .donationbox2{
    /* border: 1px solid black; */
    padding: 2rem;
    
    }
    
    .donationpaymentboxs1{
      display: flex;
      /* flex-wrap: wrap; */
      justify-content: space-between;
    }
    .donationpaymentboxs1 p{
      margin: 0;
    }
    
    .donationinput{
      display: flex;
    }
    .donationinput p{
      text-align: center;
        background-color: blue;
        height: 3rem;
        color: white;
        width: 2rem;
        font-size: 20px;
        padding: 0.4rem;
        font-weight: bold;
    
    }
    .donationinput input{
      width: 4rem;
      height: 3rem;
    }
    
    
    
    .donationpaymentboxs2{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      /* padding: 12px; */
    }
    
    .donationpaymentbox3{
    
      width: 7rem;
        height: 2.6rem;
        margin: 0.3rem 0.4rem;
        font-size: 20px;
        padding-top: 0.4rem;
        text-align: center;
        justify-content: center;
      background-color: #9186d4;
      border-radius: 10px;
      border-radius: 1.8rem 0rem;
    }
    
    #donationpaymentbox3 p{
      margin: -14px;
    }
    .donationpaymentbox3:hover{
    
     
      background-color: blue;
    }
    
    
    
    .donationpaymentbutton button{
      width: 100%;
      height: 4rem;
      font-size: 33px;
      /* font-weight: bold; */
      margin-top: 1rem;
      background-color: #4cf41a;
      border: none;
      border-radius: 20rem 2rem;
    }
    
    
    
    .homesec4donations{
      padding-bottom: 1rem;
      color: black;
    }
    
    
    
    .containers{
      background-color: #ddd;
      border-radius: 12px;
      color: #000;
      height: 19px;
      margin: 0 auto;
      padding: 0;
      width: 83%;
    }
      
    .skills {
      color: black;
      height: 19px;
      text-align: right; /* Right-align text */
      padding-top: 10px; /* Add top padding */
      padding-bottom: 10px; /* Add bottom padding */
      color: white; /* White text color */
      border-radius: 12px;
      /* width: 83%; */
    }
      
    .html {
      width: 83%; background-color: #04AA6D;
      color: black;
      font-weight: bold;
    } 
    
    
    
    
    
    
    
    
    
    
    /* payment model */
    
    
    .modalcontainer{
      position: fixed;
      overflow-y: scroll;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      height: 100vh;
      background-color: rgba(189,189,189,0.9);
      z-index: 200;
    }
    
    .modelcontent{
      overflow: scroll;
      z-index:202;
      position: fixed;
      top: 50%;
      left: 50%;
      height: 100vh;
      transform: translate(-50%,-50%);
      background-color: white;
      color: black;
    }
    
    
    .paymentupperpart{
      display: flex;
      margin: 2rem;
    }
    
    .payementvalue {
      background-color: blue;
    
    }
    .payementvalue p{
      padding: 1rem;
      font-size: 1rem;
      font-weight: bold;
      margin: 0;
    
    }
    
    .paymentinputpart input{
      width: 45rem;
        font-size: 1rem;
        padding: 1rem;
    }
    
    .paymentupperpart2{
      display: flex;
      margin: 2rem;
      text-align: center;
      justify-content: center;
    }
    
    .payementboxs{
      width: 121px;
      padding: 1rem;
      background-color: yellow;
      margin: 1rem;
      text-align: center;
    }
    .payementboxs p{
      margin: 0;
    }
    .payementboxs:hover{
      background-color: blue;
      color: white;
    }
    
    #payementboxs{
      padding: 0;
    }
    
    
    .paymentupperpart3{
      margin: 2rem;
    }
    
    .paymentmethodlinks{
      display: flex;
    }
    .paymentmethodlinks p a{
      text-decoration: none;
      padding: 12px;
      color: black;
    }
    
    
    
    .paymentmidform{
      margin: 2rem;
    }
    
    .paymentformde{
      display: flex;
      /* margin: 1rem; */
    }
    .paymentforminner{
      margin: 0rem 1rem;
    }
    .paymentinput1 input{
      width: 22rem;
      font-size: 1.2rem;
    }
    
    .paymentemail{
      margin: 1rem;
    }
    .paymentemailinput input{
      font-size: 1.2rem;
      width: 100%;
    
    }
    
    
    
    
    
    
    
    /* 14/04 */
    
    .paymentcheckbox{
      display: flex;
      justify-content: space-between;
      
      margin: 2rem;
    }
    
    .paymentcheckinpunt{
      display: flex;
    }
    .paymentcheckinpunt p{
      margin: 0;
    }
    
    
    
    .paymentdonatebutton{
      margin: 2rem;
    }
    #paymentdonatebutton1 button{
      width: 100%;
      margin-top:1rem;
      
    }
    #paymentdonatebutton1 button:hover{
     width: 100%;
      background-color: blue;
      /* margin: auto; */
    }
    
    .paymentdonationtotal{
      display: flex;
      justify-content: center;
      text-align: center;
      
    }
    .paymentdonationtotal p{
      background-color: blue;
      padding: 1rem;
      text-align: center;
    
    }
    .paymentdonationtotal input{
      
      /* padding: 0.4rem; */
      height: 56px;
      width: 38rem;
      
    
    }
    
    
    .homesec4rasegoal{
      display: flex;
      justify-content: space-between;
    }
    
    
    .closebutton{
      position: absolute;
      top: 12px;
      right: 12px;
    }
    .closebutton p i{
      font-size: 2.5rem;
      font-weight: bold;
    }
    
    
    
    
    .paymentmethodlinks p:hover  {
      color: blue;
    
    }
    
    
    .termsmenu{
      margin: 2rem;
    }
    
    
    
    
    
    
    
    /* ======================== */
    /* team */
    /* ======================== */
    
    
    
    .teamsecboxs{
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      width: 95%;
      margin: 2rem auto;
    }
    .teamsecboxs .teamsecbox{
      width: 266px;
        height: 300px;
        background-color: transparent;
        margin: 0.4rem;
        border: 2px solid thistle;
        border-radius: 25px;
        box-shadow: 0px 0px 98px 1px rgb(225, 222, 222);
    }
    
    .teamboximg{
      text-align: center;
        width: 150px;
        height: 150px;
        color: red;
        overflow: hidden;
        border-radius: 197px;
        margin: auto;
        margin-top: 2rem;
    }
    .teamboximg img{
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
    
    
    .teamboxcontent{
      text-align: center;
      padding: 1.5rem 0rem;
    }
    .teamboxcontent h3{
      font-size: 1.3rem;
    }
    .teamboxcontent h5{
      font-size: 1rem;
    }
    
    
    
    
    
    
    
    
    
    
    
    
    
    /* ========================= */
    /* footer */
    /* ========================= */
    
    .footercontainer{
    /* background-image: linear-gradient(rgb(87, 87, 139),rgb(148, 130, 98)); */
    background-image: linear-gradient(White,orange);
    }
    
    
    .footerlogo img{
      width: 9rem;
      box-shadow: 0px 0px 122px 0px #a5a5bd;
      margin-bottom: 1rem;
    }
    
    .footerboxs{
      display: flex;
      width: 95%;
      flex-wrap: wrap;
      margin: auto;
      padding: 3rem 0rem;
      justify-content: space-between;
    }
    
    .footerbox{
      margin: 1rem;
      width: 14.8rem;
      /* border: 2px solid black; */
    }
    .footerbox p a{
      color: black;
      text-decoration: none;
      font-size: 1.2rem;
      
    }

    .footeremailpara p{

      font-size: 1rem;
    
    }
    .footerbox p a:hover{
      color:rgb(19, 18, 18);
      /* background-color: black; */
      text-decoration: none;
      font-size: 1.2rem;
      
    }
    .footerbox p {
      /* font-size: 1.2rem; */
      
    }
    
    .footerbox h1{
      padding-bottom: 1rem;
    }


    
.navlogolinks i{
      padding: 0.3rem;
    font-size: 1.7rem;
}
.navlogolinks a{
  text-decoration: none;
  color: black;
      
}

    
    
    
    
    
    
    /* ............................................... */
    
    .aboutimgbox1{
      position: relative;
    }
    .imghoverbutton1{
      background: rgb(0,0,0,.6);
        /* display: flex; */
        height: 100%;
        justify-content: center;
        left: 0;
        opacity: 0;
        position: absolute;
        text-align: center;
        top: 0;
        transition: .6s;
        width: 100%;
    }
    .imghoverbutton1:hover{
      
        opacity: 1;
        
    }
    .imghoverbutton1 button{
      
      /* position: absolute; */
      top: 36%;
      border-radius: 12px;
        background-color: orange;
        padding: 0.6rem 1rem;
        height: 3rem;
        margin-top: 9rem;
        
    }
    .imghoverbutton{
      background: rgb(0,0,0,.6);
        /* display: flex; */
        height: 100%;
        justify-content: center;
        left: 0;
        opacity: 0;
        position: absolute;
        text-align: center;
        top: 0;
        transition: .6s;
        width: 100%;
    }
    .imghoverbutton:hover{
      
        opacity: 1;
        
    }
    .imghoverbutton button{
      
      /* position: absolute; */
      top: 36%;
      border-radius: 12px;
        background-color: orange;
        padding: 0.6rem 1rem;
        height: 3rem;
        margin-top: 5rem;
        
    }
    
    
    
    
    
    
    
    }


@media (min-width: 1180px) and (max-width:1300px){



/* ================================ */
/* Navbar */
/* ================================ */
*{
  margin: 0;
  padding: 0;
}
.navbarcontainer{
  background-color: transparent;
  position: relative;
  z-index: 99;
  /* box-shadow: 1px 1px 167px 1px orange; */

}
.navbarboxs{
display: flex;
justify-content: space-between;
width: 80%;
margin: auto;
}


.menubutton{
  display: none;
}

.navlogobackground {
  background-color: white;
    width: 11rem;
    height: 10rem;
    position: absolute;
    left: 134px;
    z-index: 23;

}
.navbarbox .navbarimgbox img{
  width: 8rem;
  /* padding: 1rem; */
  position: absolute;
  z-index: 30;
  margin-top: 1rem;
  /* box-shadow: 1px 1px 167px 1px orange; */


}
.navbarbox ul{
  display: flex;
  padding-right: 3rem;

}
.navbarbox ul li{
  list-style: none;
  margin: 1rem;

}
.navbarbox ul li a{
  text-decoration: none;
  color: black;
  z-index: 99;
  font-weight: bold;
  font-size: 1.3rem;

}








/* toolbar */

.toolbar{
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  margin-left: 20px;
margin-top: 10px;
/* box-shadow: 1px 1px 1px 1px rgb(152, 152, 178); */
}

.imglogo img{
width: 40px;
margin-right: 17px;
}
.sidebar{
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  background-color: black;
  color: white;
  width: 280px;
  transform:translateX(-100%) ;
  transition: all 0.4s;
  z-index: 1;
}


.downbar{
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 40%;
  background-color: black;
  color: white;
  width: 100%;
  transform:translateY(0%) ;
  transition: all 0.4s;
}
.profile{
  display: flex;
  text-align: center;
  flex-direction: column;
  margin: 20px 80px;
  width: 100px;
}

.homelinks li{
margin: 15px 30px;
font-size: 17px;
list-style: none;
}
.homelinks li a{
color: white;
text-decoration: none;
}
.homelinks li i{
margin-right: 30px;
}
.profileimg img{
  width: 100px;
  height: 100px;
  border-radius: 73px;
}



.sidebaropen{
  transform:translateX(0%) ;
  
}
.downbaropen{
  transform:translateY(0%) ;
  
}
.backdrop{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  display: none;
}

.backdropopen{
  display: block;
}













/* ====================== */
/* home */
/* ====================== */

.homecontaner{
  width: 100%;
  height: 47rem;
  /* justify-content: center; */
  /* text-align: center; */
  display: flex;
  /* background-image: linear-gradient(45deg, rgb(224 218 207),rgb(208 202 202),rgb(209 209 215)); */
  background-image: linear-gradient(180deg, White 25%,orange);
z-index: 2;
margin-top: -5rem;
}
.homeboxs{
display: flex;
width: 90%;
margin: auto;
justify-content: center;
position: relative;
padding-top: 12rem;
padding-bottom: 5rem;

}
.homebox:first-child{
margin-right: 18rem;

}
.homebox{
  width: 30rem;
  z-index: 5;

}
.homebox h2{
  font-weight: bold;
  padding: 1rem 0rem;
  font-size: 2.3rem;
  font-family: 'Inter',sans-serif;
}
.homebox h1{
  font-weight: bold;
  padding: 1rem 0rem;
  font-size: 3.5rem;
  font-family: 'Inter',sans-serif;
  margin-top: 4rem;
}
.homebox p{
  /* font-weight: bold; */
  /* padding: 1rem 0rem; */
  font-size: 18px;
  /* font-family: 'Inter',sans-serif; */
}
.homebox1{
  position: absolute;
  /* width: 300rem; */
  top: 6.8rem;
  z-index: 3;
  opacity: 0.8;

}

.homebox1 {
  width: 20rem;
  height: 30em;
}
.homebox1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.teamheading{
  width: 80%;
  margin: auto;
  margin-top: 1rem;
}

.homebutton button{
  width: 8rem;
  border-radius: 12px;
  background-color: orange;
  padding: 0.6rem 0rem;
}


/* =========================== */
/* homesec1 */
/* =========================== */

.homesec1container{

}
.homesec1boxs{
display: flex;
width: 80%;
margin: auto;
justify-content: center;
}
.homesec1box{
  margin: 1rem;
  background-color: rgb(170, 161, 161);
  border-radius: 12px;
  
}

.homesec1boximg{

}






/* ========================== */
/* ourMission */
/* ========================== */


.homeourmissioncontainer{

}
.homeourmissionbox1{
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 2rem;
}
.homeourmissionbox1 h1{
  font-size: 2.5rem;
}
.homeourmissionbox1 p{
  font-size: 1.3rem;
}

.homeourmissionboxs{
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.homeourmissionbox{
  border: 2px solid rgb(245, 239, 239);
  width: 19rem;
  height: 15rem;
  text-align: center;
  margin: 1rem;
}
.homeourmissionbox h1{
  font-size: 1.5rem;
  padding-bottom: 1rem;
 
}
.homeourmissionbox p{
  font-size: 1rem;
 
}
.homeourmissionimg {
  width:54px;
  /* height: 120px;  */
  margin: 1rem auto;
  
}
.homeourmissionimg img{
  max-width: 100%;
  filter: hue-rotate(45deg);
  
}


.homeourmissionbox:hover{
  transform: scale(1.1);
      transition: all 2s;
  
}


/* ============================ */
/* homeourproject */
/* ============================ */

.homeourprojectcontainer{

}
.homeprojectcontent{
  width: 90%;
  margin: 3rem auto;
  text-align: center;
}
.homeprojectcontent h1{
  font-size: 2.5rem;
  padding-bottom: 1rem;
}

.homeprojectimgboxs{
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.homeprojectimgbox{
  width: 31rem;
  /* border: 2px solid rgb(188, 178, 178); */
  position: relative;
  margin: 1rem;
}
.homeprojectimgbox h2{
  padding: 1rem;


}
.homeprojectimg{
  width: 100%;
  height: 17rem;
  background-color: blue;
  border-radius: 12px ;
  overflow: hidden;
  box-shadow:  1px 1px 32px 1px #c8c8d7;
}
.homeprojectimg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* box-shadow: 1px 1px 12px 1px blue; */
}
.homeprojectimg img:hover{
  transform: scale(1.1);
  transition: all 2s;
}





/* ============================= */
/* contact us */
/* ============================= */

.contactcontainer{

}

.contactupper{

}
.contactupperbox{
  width: 27rem;
    height: 18rem;
    /* background-color: blue; */
    /* margin: 0rem 9rem; */
    /* margin-left: 13rem; */
    box-shadow: 1px 1px 30px 1px blue;
    margin-left: 11rem;
}
.contactupperbox2 h1{
  margin: 4rem;
  font-size: 1.5rem;
}
.contactupperbox img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contactupperbox1{
  position: absolute;
  width: 27rem;
    height: 18rem;
    top: 18rem;
    left: 38rem;
    box-shadow: 1px 1px 30px 1px blue;
}

.contactdonatebutton{
  position: absolute;
  top: 26rem;
  left: 20rem;
}
.contactdonatebutton button{

      width: 8rem;
    border-radius: 12px;
    background-color: orange;
    padding: 0.6rem 0rem;
}

.contactupperbox2{
  width: 26rem;
}
.contactupperbox2 a{
  font-size: 2rem;
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.contactupperbox1 img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contactupperimg{
  display: flex;
  /* justify-content: center; */
  position: relative;
  width: 80%;
  margin: 5rem auto;
}


#contactformcontainer{
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin:  auto;
  margin-top: 3rem;
  /* margin-top: 30rem; */
  padding-top: 1rem;
}

.contactbox h1{
  padding-bottom: 1rem;

}

.contacelomks a{
  text-decoration: none;
  color: black;
}
.contacelomks a i {
  font-size: 1.3rem;
  padding: 0.3rem;
}


.forminput input{
  width: 19rem;
    font-size: 1.3rem;
    padding: 6px;
    margin: 2rem 0px;
    border: none;
    border-bottom: 1px solid #b4a4a4;
}

.formtextarea textarea{
  width: 19rem;
  height: 4rem;
    font-size: 1.3rem;
    padding: 6px;
    margin: 4px 0px;
    border: none;
    border-bottom: 1px solid #b4a4a4;
}

.formbutton button{
  padding: 0.8rem;
  background-color: blue;
  color: white;
  border-radius: 4px;
}




/* ============================ */
/* about */
/* ============================ */


#more {display: none;}

.aboutsec1container{

}


/* aboutsec1 */

.aboutuppersec1boxs{
  display: flex;
}

.aboutuppersec1box{
  width: 100%;
  /* margin: 0.2rem; */
  height: 33.5rem;
  /* background-color: blue; */
  background-image: linear-gradient(180deg, White 12% ,orange);
  /* background-color: orange; */
  overflow: hidden;
  position: relative;
}

.pageupperheading1{
  text-align: center;
}
.aboutuppersec1box h1{
  /* color: cornsilk; */
  color: black;
    /* font-family: 'Architects Daughter', cursive; */
    /* font-family: 'Cookie', cursive; */
    /* font-family: 'Fira Sans', sans-serif; */
    /* font-family: 'Libre Baskerville', serif; */
    font-size: 1.3rem;
    /* font-family: cursive; */
    padding: 2rem;
    text-align: center;
    font-weight: bolder;
    margin-top: 5rem;
    /* background: rgb(131,58,168); */
    /* background-image: linear-gradient(110deg,rgb(171 5 237) ,rgb(20 26 27)); */
    /* background-clip: text; */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */

}

.homebutton2 {
 
  text-align: center;
}
.homebutton2 button{
  background-color: orange;
  padding: 0.6rem;
  border-radius: 12px;
  text-align: center;
  color: white;
  box-shadow: 1px 1px 30px 1px blue;
}
.homebutton2 button:hover{
  background-color: rgb(97, 97, 172);
}

.aboutimgbox3{
  width: 100%;
  height: 19rem;
  margin-top: 5rem;
}
.aboutimgbox3 img{
  width: 100%;
  height: 100%;
}

.aboutuppersec1box img:hover{
  transform: scale(1.1);
  transition: all 2s;
}




.aboutsec1boxs{
display: flex;
padding-top: 2rem;
justify-content: space-between;
width: 90%;
margin: auto;
}

.aboutsecbox{
width: 40rem;
height: 30rem;
padding: 2rem;
overflow: auto;
}

.aboutsecbox img{
width: 100%;
height: 100%;

}
.aboutimgbox1{
  overflow: hidden;
}
.aboutsecbox img:hover{
  transform: scale(1.1);
  transition: all 2s;

}
.aboutsecbox button{
  padding: 0.4rem;
  background-color: blue;
  color: white;
  margin-top: 1rem;
}

.aboutcards{
  display: flex;
  flex-direction: row;
  position: relative;
  background-image: linear-gradient(120deg,rgba(233, 138, 69, 0.9) 50% , transparent 50%),url('https://insidearabia.com/wp-content/uploads/2020/05/IMG_2393.jpg');
}
.aboutcardcontent{
  padding: 2rem;
  width: 33rem;
}
.cardimg1{
  width: 90rem;
  height: 23rem;
}
.cardimg1 img{
  width: 100%;
  height: 100%;
}

.arro{
  width: 50%;
    margin: auto;
    text-align: center;
    margin-top: 2rem;

}


/* Aboutourmission */

.aboutmissionboxs{
  width: 90%;
  margin: auto;
  display: flex;
  /* flex-wrap: wrap; */
  text-align: center;
  justify-content: center;
  

}
.aboutmissionbox{
  width: 100%;
  margin: 0.6rem;
  padding: 0.7rem;
  box-shadow: 1px 1px 30px 1px black;
  border: 1px solid rgb(164, 157, 157);
}

.aboutmissionbox1{
  width: 88%;
  margin: 0.6rem auto;
  padding: 0.7rem;
  box-shadow: 1px 1px 30px 1px black;
  border: 1px solid rgb(164, 157, 157);
  text-align: center;
}
.box{
  width: 100%;
  margin: auto;
}










/* ====================================== */
/* OurCauses */
/* ====================================== */

.causesbox2{

}
.causesboxs{
  padding-top: 3rem;
  margin: auto;
  width: 95%;

}
.causesbox1{
  width: 80%;
  margin: auto;
  text-align: center;
}
.causescards{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}
.causescard{
  width: 20rem;
  height: 22rem;
  margin: 1rem;
  box-shadow: 1px 1px 34px 1px orange;

  background-color:#f9e2e2;

}
.causescard h3{
  padding: 1rem;
  font-size: 1.5rem;

}
.causescardimg{
  width: 100%;
  height: 14rem;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

}
.causescardimg img{
  width: 100%;
  height: 100%;
  /* object-fit: cover; */

}
.causescardimg img:hover{
 transform: scale(1.1);
 transition: all 2s;

}

.pageupperheading{
  text-align: center;
  background-color: orange;
}
.pageupperheading h1{
  margin: 0;
}






/* new gallary */

.gallarybox12{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 1rem; */
}
.piccontainer{
  width: 29%;
  /* display: flex; */
  margin: 1rem;
  /* height: 21rem; */
  
}
.piccontainer img{
  width: 100%;
  height: 100%;

}



  .model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    background-color:transparent;
    display: none;
    text-align: center;
    /* display: flex; */
    /* position: relative; */
  }

  .model::after{
    background: rgba(8, 6, 19, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
 
.modelimgcontainer{
    width: 60%;
    /* height: 100vh; */
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 3rem;
}
.modelimgcontainer img{
  width: 100%;
  height: 100%;
  object-fit: cover;

}

  .open{
    display: block;
  }

  .modelimgcontainer h1 i{
    color: white;
  }







/* ====================================== */
/* gallary */
/* ====================================== */




.gallarysection2{
  width: 80%;
  margin: auto;

}

.videoclipcontainer{
  display: flex;
  justify-content: center;
  text-align: center;
}

.videoclip1{
  position: relative;
  width: 580px;
  height: 400px;
  background-color: rgb(169, 169, 189);
  margin: 1rem;
}
.videoclip1 iframe{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallarysection3{
  margin: auto;
  width: 90%;
}

.gallarysec3cards{
  display: flex;
  justify-content: center;
  text-align: center;
}
.gallarysec3card {
  width: 24.1rem;
  height:18rem;
  margin: 1rem;
  background-color: blue;
}
.gallarysec3card img{
  /* width: 23.6rem; */
  /* height:18rem; */
  /* margin: 1rem; */


    width: 100%;
    height: 100%;
    /* margin: 1rem; */
    /* max-width: 100%; */
    /* max-height: 100%; */
    object-fit: cover;
  
}


/* more media content */


.moremediacontainer{
  margin-top: 8rem;
}

















/* donation */


.donationboxcontainer{
  width: 90%;
  margin: 2rem auto;
}


.donationboxs{
  display: flex;
  /* background-color: blue; */
}
.donationbox1{
  background-color: #d2301c;
  padding: 4rem;
  color: white;
  margin: 0rem 1rem;
  border-radius: 0rem 0rem 0rem 20rem ;
}

.donationbox2{
/* border: 1px solid black; */
padding: 2rem;

}

.donationpaymentboxs1{
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
}
.donationpaymentboxs1 p{
  margin: 0;
}

.donationinput{
  display: flex;
}
.donationinput p{
  text-align: center;
    background-color: blue;
    height: 4rem;
    color: white;
    width: 2rem;
    font-size: 30px;
    padding: 0.4rem;
    font-weight: bold;

}
.donationinput input{
  width: 6rem;
  height: 4rem;
}



.donationpaymentboxs2{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* padding: 12px; */
}

.donationpaymentbox3{

  width: 7rem;
    height: 2.6rem;
    margin: 0.3rem 0.4rem;
    font-size: 20px;
    padding-top: 0.4rem;
    text-align: center;
    justify-content: center;
  background-color: #9186d4;
  border-radius: 10px;
  border-radius: 1.8rem 0rem;
}

#donationpaymentbox3 p{
  margin: -14px;
}
.donationpaymentbox3:hover{

 
  background-color: blue;
}



.donationpaymentbutton button{
  width: 100%;
  height: 4rem;
  font-size: 33px;
  /* font-weight: bold; */
  margin-top: 1rem;
  background-color: #4cf41a;
  border: none;
  border-radius: 20rem 2rem;
}



.homesec4donations{
  padding-bottom: 1rem;
  color: black;
}



.containers{
  background-color: #ddd;
  border-radius: 12px;
  color: #000;
  height: 19px;
  margin: 0 auto;
  padding: 0;
  width: 83%;
}
  
.skills {
  color: black;
  height: 19px;
  text-align: right; /* Right-align text */
  padding-top: 10px; /* Add top padding */
  padding-bottom: 10px; /* Add bottom padding */
  color: white; /* White text color */
  border-radius: 12px;
  /* width: 83%; */
}
  
.html {
  width: 83%; background-color: #04AA6D;
  color: black;
  font-weight: bold;
} 










/* payment model */


.modalcontainer{
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(189,189,189,0.9);
  z-index: 200;
}

.modelcontent{
  overflow: scroll;
  z-index: 202;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100vh;
  transform: translate(-50%,-50%);
  background-color: white;
  color: black;
}


.paymentupperpart{
  display: flex;
  margin: 2rem;
}

.payementvalue {
  background-color: blue;

}
.payementvalue p{
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;

}

.paymentinputpart input{
  width: 45rem;
    font-size: 1rem;
    padding: 1rem;
}

.paymentupperpart2{
  display: flex;
  margin: 2rem;
  text-align: center;
  justify-content: center;
}

.payementboxs{
  width: 121px;
  padding: 1rem;
  background-color: yellow;
  margin: 1rem;
  text-align: center;
}
.payementboxs p{
  margin: 0;
}
.payementboxs:hover{
  background-color: blue;
  color: white;
}

#payementboxs{
  padding: 0;
}


.paymentupperpart3{
  margin: 2rem;
}

.paymentmethodlinks{
  display: flex;
}
.paymentmethodlinks p a{
  text-decoration: none;
  padding: 12px;
  color: black;
}



.paymentmidform{
  margin: 2rem;
}

.paymentformde{
  display: flex;
  /* margin: 1rem; */
}
.paymentforminner{
  margin: 0rem 1rem;
}
.paymentinput1 input{
  width: 22rem;
  font-size: 1.2rem;
}

.paymentemail{
  margin: 1rem;
}
.paymentemailinput input{
  font-size: 1.2rem;
  width: 100%;

}







/* 14/04 */

.paymentcheckbox{
  display: flex;
  justify-content: space-between;
  
  margin: 2rem;
}

.paymentcheckinpunt{
  display: flex;
}
.paymentcheckinpunt p{
  margin: 0;
}



.paymentdonatebutton{
  margin: 2rem;
}
#paymentdonatebutton1 button{
  width: 100%;
  margin-top:1rem;
  
}
#paymentdonatebutton1 button:hover{
 width: 100%;
  background-color: blue;
  /* margin: auto; */
}

.paymentdonationtotal{
  display: flex;
  justify-content: center;
  text-align: center;
  
}
.paymentdonationtotal p{
  background-color: blue;
  padding: 1rem;
  text-align: center;

}
.paymentdonationtotal input{
  
  /* padding: 0.4rem; */
  height: 56px;
  width: 38rem;
  

}


.homesec4rasegoal{
  display: flex;
  justify-content: space-between;
}


.closebutton{
  position: absolute;
  top: 12px;
  right: 12px;
}
.closebutton p i{
  font-size: 2.5rem;
  font-weight: bold;
}




.paymentmethodlinks p:hover  {
  color: blue;

}


.termsmenu{
  margin: 2rem;
}







/* ======================== */
/* team */
/* ======================== */



.teamsecboxs{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 95%;
  margin: 2rem auto;
}
.teamsecboxs .teamsecbox{
  width: 266px;
    height: 300px;
    background-color: transparent;
    margin: 0.4rem;
    border: 2px solid thistle;
    border-radius: 25px;
    box-shadow: 0px 0px 98px 1px rgb(225, 222, 222);
}

.teamboximg{
  text-align: center;
    width: 150px;
    height: 150px;
    color: red;
    overflow: hidden;
    border-radius: 197px;
    margin: auto;
    margin-top: 2rem;
}
.teamboximg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.teamboxcontent{
  text-align: center;
  padding: 1.5rem 0rem;
}
.teamboxcontent h3{
  font-size: 1.3rem;
}
.teamboxcontent h5{
  font-size: 1rem;
}













/* ========================= */
/* footer */
/* ========================= */

.footercontainer{
/* background-image: linear-gradient(rgb(87, 87, 139),rgb(148, 130, 98)); */
background-image: linear-gradient(White,orange);
}


.footerlogo img{
  width: 9rem;
  box-shadow: 0px 0px 122px 0px #a5a5bd;
  margin-bottom: 1rem;
}

.footerboxs{
  display: flex;
  width: 95%;
  flex-wrap: wrap;
  margin: auto;
  padding: 3rem 0rem;
  justify-content: space-between;
}

.footerbox{
  margin: 1rem;
  width: 14.8rem;
  /* border: 2px solid black; */
}
.footerbox p a{
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  
}
.footerbox p a:hover{
  color:rgb(19, 18, 18);
  /* background-color: black; */
  text-decoration: none;
  font-size: 1.2rem;
  
}
.footerbox p {
  font-size: 1.2rem;
  
}

.footerbox h1{
  padding-bottom: 1rem;
}



.navlogolinks i{
      padding: 0.3rem;
    font-size: 1.7rem;
}
.navlogolinks a{
  text-decoration: none;
  color: black;
      
}







/* ............................................... */

.aboutimgbox1{
  position: relative;
}
.imghoverbutton1{
  background: rgb(0,0,0,.6);
    /* display: flex; */
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: .6s;
    width: 100%;
}
.imghoverbutton1:hover{
  
    opacity: 1;
    
}
.imghoverbutton1 button{
  
  /* position: absolute; */
  top: 36%;
  border-radius: 12px;
    background-color: orange;
    padding: 0.6rem 1rem;
    height: 3rem;
    margin-top: 9rem;
    
}
.imghoverbutton{
  background: rgb(0,0,0,.6);
    /* display: flex; */
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: .6s;
    width: 100%;
}
.imghoverbutton:hover{
  
    opacity: 1;
    
}
.imghoverbutton button{
  
  /* position: absolute; */
  top: 36%;
  border-radius: 12px;
    background-color: orange;
    padding: 0.6rem 1rem;
    height: 3rem;
    margin-top: 5rem;
    
}







}

@media (min-width: 1300px){



/* ================================ */
/* Navbar */
/* ================================ */
*{
  margin: 0;
  padding: 0;
}
.navbarcontainer{
  background-color: transparent;
  position: relative;
  z-index: 99;
  /* box-shadow: 1px 1px 167px 1px orange; */

}
.navbarboxs{
display: flex;
justify-content: space-between;
width: 80%;
margin: auto;
}


.menubutton{
  display: none;
}

.navlogobackground {
  background-color: white;
    width: 11rem;
    height: 10rem;
    position: absolute;
    left: 134px;
    z-index: 23;

}
.navbarbox .navbarimgbox img{
  width: 9rem;
  /* padding: 1rem; */
  position: absolute;
  z-index: 30;
  margin-top: 1rem;
  /* box-shadow: 1px 1px 167px 1px orange; */


}
.navbarbox ul{
  display: flex;
  padding-right: 10rem;

}
.navbarbox ul li{
  list-style: none;
  margin: 1rem;

}
.navbarbox ul li a{
  text-decoration: none;
  color: black;
  z-index: 99;
  font-weight: bold;
  font-size: 1.3rem;

}








/* toolbar */

.toolbar{
  display: flex;
  justify-content: space-between;
  font-size: 30px;
  margin-left: 20px;
margin-top: 10px;
/* box-shadow: 1px 1px 1px 1px rgb(152, 152, 178); */
}

.imglogo img{
width: 40px;
margin-right: 17px;
}
.sidebar{
  overflow: hidden;
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100vh;
  background-color: black;
  color: white;
  width: 280px;
  transform:translateX(-100%) ;
  transition: all 0.4s;
  z-index: 1;
}


.downbar{
  position: absolute;
  bottom: 0px;
  left: 0px;
  height: 40%;
  background-color: black;
  color: white;
  width: 100%;
  transform:translateY(0%) ;
  transition: all 0.4s;
}
.profile{
  display: flex;
  text-align: center;
  flex-direction: column;
  margin: 20px 80px;
  width: 100px;
}

.homelinks li{
margin: 15px 30px;
font-size: 17px;
list-style: none;
}
.homelinks li a{
color: white;
text-decoration: none;
}
.homelinks li i{
margin-right: 30px;
}
.profileimg img{
  width: 100px;
  height: 100px;
  border-radius: 73px;
}



.sidebaropen{
  transform:translateX(0%) ;
  
}
.downbaropen{
  transform:translateY(0%) ;
  
}
.backdrop{
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
  background-color: transparent;
  display: none;
}

.backdropopen{
  display: block;
}





/* ====================== */
/* home */
/* ====================== */

.homecontaner{
  width: 100%;
  height: 43rem;
  /* justify-content: center; */
  /* text-align: center; */
  display: flex;
  /* background-image: linear-gradient(45deg, rgb(224 218 207),rgb(208 202 202),rgb(209 209 215)); */
  background-image: linear-gradient(180deg, White 25%,orange);
z-index: 2;
margin-top: -5rem;
position: relative;
}
.homeboxs{
display: flex;
width: 90%;
margin: auto;
justify-content: center;
position: relative;
padding-top:11rem;
padding-bottom: 5rem;

}
.homebox:first-child{
margin-right: 18rem;

}
.homebox{
  width: 30rem;
  z-index: 5;

}
.homebox h2{
  font-weight: bold;
  padding: 1rem 0rem;
  font-size: 2.3rem;
  font-family: 'Inter',sans-serif;
}
.homebox h1{
  font-weight: bold;
  padding: 1rem 0rem;
  font-size: 4.5rem;
  font-family: 'Inter',sans-serif;
}
.homebox p{
  /* font-weight: bold; */
  /* padding: 1rem 0rem; */
  font-size: 18px;
  /* font-family: 'Inter',sans-serif; */
}
.homebox1{
  position: absolute;
  /* width: 300rem; */
  /* top:1.8rem; */
  bottom: -2.4rem;
  z-index: 3;
  opacity: 0.8;

}

.homebox1 {
  width: 22rem;
  height: 33.2rem;
}
.homebox1 img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.teamheading{
  width: 80%;
  margin: auto;
  margin-top: 1rem;
}

.homebutton button{
  width: 8rem;
  border-radius: 12px;
  background-color: orange;
  padding: 0.6rem 0rem;
}


/* =========================== */
/* homesec1 */
/* =========================== */

.homesec1container{

}
.homesec1boxs{
display: flex;
width: 80%;
margin: auto;
justify-content: center;
}
.homesec1box{
  margin: 1rem;
  background-color: rgb(170, 161, 161);
  border-radius: 12px;
  
}

.homesec1boximg{

}






/* ========================== */
/* ourMission */
/* ========================== */


.homeourmissioncontainer{

}
.homeourmissionbox1{
  width: 80%;
  margin: auto;
  text-align: center;
  padding: 2rem;
}
.homeourmissionbox1 h1{
  font-size: 2.5rem;
}
.homeourmissionbox1 p{
  font-size: 1.3rem;
}

.homeourmissionboxs{
  width: 90%;
  margin: auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.homeourmissionbox{
  border: 2px solid rgb(245, 239, 239);
  width: 19rem;
  height: 15rem;
  text-align: center;
  margin: 1rem;
}
.homeourmissionbox h1{
  font-size: 1.5rem;
  padding-bottom: 1rem;
 
}
.homeourmissionbox p{
  font-size: 1rem;
 
}
.homeourmissionimg {
  width:54px;
  /* height: 120px;  */
  margin: 1rem auto;
  
}
.homeourmissionimg img{
  max-width: 100%;
  filter: hue-rotate(45deg);
  
}


.homeourmissionbox:hover{
  transform: scale(1.1);
      transition: all 2s;
  
}



/* ============================ */
/* homeourproject */
/* ============================ */

.homeourprojectcontainer{

}
.homeprojectcontent{
  width: 90%;
  margin: 3rem auto;
  text-align: center;
}
.homeprojectcontent h1{
  font-size: 2.5rem;
  padding-bottom: 1rem;
}

.homeprojectimgboxs{
  width: 90%;
  margin: auto;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.homeprojectimgbox{
  width: 31rem;
  /* border: 2px solid rgb(188, 178, 178); */
  position: relative;
  margin: 1rem;
}
.homeprojectimgbox h2{
  padding: 1rem;


}
.homeprojectimg{
  width: 100%;
  height: 17rem;
  background-color: blue;
  border-radius: 12px ;
  overflow: hidden;
  box-shadow:  1px 1px 32px 1px #c8c8d7;
}
.homeprojectimg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  /* box-shadow: 1px 1px 12px 1px blue; */
}
.homeprojectimg img:hover{
  transform: scale(1.1);
  transition: all 2s;
}





/* ============================= */
/* contact us */
/* ============================= */

.contactcontainer{

}

.contactupper{

}
.contactupperbox{
  width: 27rem;
    height: 18rem;
    /* background-color: blue; */
    /* margin: 0rem 9rem; */
    /* margin-left: 13rem; */
    box-shadow: 1px 1px 30px 1px blue;
    margin-left: 11rem;
}
.contactupperbox2 h1{
  margin: 4rem;
  font-size: 1.5rem;
}
.contactupperbox img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contactupperbox1{
  position: absolute;
  width: 27rem;
    height: 18rem;
    top: 18rem;
    left: 38rem;
    box-shadow: 1px 1px 30px 1px blue;
}

.contactdonatebutton{
  position: absolute;
  top: 26rem;
  left: 20rem;
}
.contactdonatebutton button{

      width: 8rem;
    border-radius: 12px;
    background-color: orange;
    padding: 0.6rem 0rem;
}

.contactupperbox2{
  width: 26rem;
}
.contactupperbox2 a{
  font-size: 2rem;
  text-decoration: none;
  color: black;
  font-weight: bold;
}
.contactupperbox1 img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.contactupperimg{
  display: flex;
  /* justify-content: center; */
  position: relative;
  width: 80%;
  margin: 5rem auto;
}


#contactformcontainer{
  display: flex;
  justify-content: space-around;
  width: 90%;
  margin:  auto;
  margin-top: 3rem;
  /* margin-top: 30rem; */
  padding-top: 1rem;
}

.contactbox h1{
  padding-bottom: 1rem;

}

.forminput input{
  width: 19rem;
    font-size: 1.3rem;
    padding: 6px;
    margin: 2rem 0px;
    border: none;
    border-bottom: 1px solid #b4a4a4;
}

.formtextarea textarea{
  width: 19rem;
  height: 4rem;
    font-size: 1.3rem;
    padding: 6px;
    margin: 4px 0px;
    border: none;
    border-bottom: 1px solid #b4a4a4;
}

.formbutton button{
  padding: 0.8rem;
  background-color: blue;
  color: white;
  border-radius: 4px;
}



.contacelomks a{
  text-decoration: none;
  color: black;
}
.contacelomks a i {
  font-size: 1.3rem;
  padding: 0.3rem;
}




/* ============================ */
/* about */
/* ============================ */


#more {display: none;}

.aboutsec1container{

}


/* aboutsec1 */

.aboutuppersec1boxs{
  display: flex;
}

.aboutuppersec1box{
  width: 100%;
  /* margin: 0.2rem; */
  height: 33.5rem;
  /* background-color: blue; */
  background-image: linear-gradient(180deg, White 12% ,orange);
  /* background-color: orange; */
  overflow: hidden;
  position: relative;
}

.pageupperheading1{
  text-align: center;
}
.aboutuppersec1box h1{
  /* color: cornsilk; */
  color: black;
    /* font-family: 'Architects Daughter', cursive; */
    /* font-family: 'Cookie', cursive; */
    /* font-family: 'Fira Sans', sans-serif; */
    /* font-family: 'Libre Baskerville', serif; */
    font-size: 1.3rem;
    /* font-family: cursive; */
    padding: 2rem;
    text-align: center;
    font-weight: bolder;
    margin-top: 5rem;
    /* background: rgb(131,58,168); */
    /* background-image: linear-gradient(110deg,rgb(171 5 237) ,rgb(20 26 27)); */
    /* background-clip: text; */
    /* -webkit-background-clip: text; */
    /* -webkit-text-fill-color: transparent; */

}

.homebutton2 {
 
  text-align: center;
}
.homebutton2 button{
  background-color: orange;
  padding: 0.6rem;
  border-radius: 12px;
  text-align: center;
  color: white;
  box-shadow: 1px 1px 30px 1px blue;
}
.homebutton2 button:hover{
  background-color: rgb(97, 97, 172);
}

.aboutimgbox3{
  width: 100%;
  height: 19rem;
  margin-top: 5rem;
}
.aboutimgbox3 img{
  width: 100%;
  height: 100%;
}

.aboutuppersec1box img:hover{
  transform: scale(1.1);
  transition: all 2s;
}




.aboutsec1boxs{
display: flex;
padding-top: 2rem;
justify-content: space-between;
width: 90%;
margin: auto;
}

.aboutsecbox{
width: 40rem;
height: 30rem;
padding: 2rem;
overflow: auto;
}

.aboutsecbox img{
width: 100%;
height: 100%;

}
.aboutimgbox1{
  overflow: hidden;
}
.aboutsecbox img:hover{
  transform: scale(1.1);
  transition: all 2s;

}
.aboutsecbox button{
  padding: 0.4rem;
  background-color: blue;
  color: white;
  margin-top: 1rem;
}

.aboutcards{
  display: flex;
  flex-direction: row;
  position: relative;
  background-image: linear-gradient(120deg,rgba(233, 138, 69, 0.9) 50% , transparent 50%),url('https://insidearabia.com/wp-content/uploads/2020/05/IMG_2393.jpg');
}
.aboutcardcontent{
  padding: 2rem;
  width: 33rem;
}
.cardimg1{
  width: 90rem;
  height: 23rem;
}
.cardimg1 img{
  width: 100%;
  height: 100%;
}

.arro{
  width: 50%;
    margin: auto;
    text-align: center;
    margin-top: 2rem;

}


/* Aboutourmission */

.aboutmissionboxs{
  width: 90%;
  margin: auto;
  display: flex;
  /* flex-wrap: wrap; */
  text-align: center;
  justify-content: center;
  

}
.aboutmissionbox{
  width: 100%;
  margin: 0.6rem;
  padding: 0.7rem;
  box-shadow: 1px 1px 30px 1px black;
  border: 1px solid rgb(164, 157, 157);
}

.aboutmissionbox1{
  width: 88%;
  margin: 0.6rem auto;
  padding: 0.7rem;
  box-shadow: 1px 1px 30px 1px black;
  border: 1px solid rgb(164, 157, 157);
  text-align: center;
}
.box{
  width: 100%;
  margin: auto;
}










/* ====================================== */
/* OurCauses */
/* ====================================== */

.causesbox2{

}
.causesboxs{
  padding-top: 3rem;
  margin: auto;
  width: 95%;

}
.causesbox1{
  width: 80%;
  margin: auto;
  text-align: center;
}
.causescards{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

}
.causescard{
  width: 22rem;
  height: 22rem;
  margin: 1rem;
  box-shadow: 1px 1px 34px 1px orange;

  background-color:#f9e2e2;

}
.causescard h3{
  padding: 1rem;

}
.causescardimg{
  width: 22rem;
  height: 14rem;
  border-radius: 12px;
  overflow: hidden;
  position: relative;

}
.causescardimg img{
  width: 100%;
  height: 100%;
  /* object-fit: cover; */

}
.causescardimg img:hover{
 transform: scale(1.1);
 transition: all 2s;

}

.pageupperheading{
  text-align: center;
  background-color: orange;
}
.pageupperheading h1{
  margin: 0;
}










/* ====================================== */
/* gallary */
/* ====================================== */




.gallarysection2{
  width: 80%;
  margin: auto;

}

.videoclipcontainer{
  display: flex;
  justify-content: center;
  text-align: center;
}

.videoclip1{
  position: relative;
  width: 580px;
  height: 400px;
  background-color: rgb(174, 174, 190);
  margin: 1rem;
}
.videoclip1 iframe{
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.gallarysection3{
  margin: auto;
  width: 90%;
}

.gallarysec3cards{
  display: flex;
  justify-content: center;
  text-align: center;
}
.gallarysec3card {
  width: 24.1rem;
  height:18rem;
  margin: 1rem;
  background-color: blue;
}
.gallarysec3card img{
  /* width: 23.6rem; */
  /* height:18rem; */
  /* margin: 1rem; */


    width: 100%;
    height: 100%;
    /* margin: 1rem; */
    /* max-width: 100%; */
    /* max-height: 100%; */
    object-fit: cover;
  
}





.morecontent{
  text-align: right;
}


/* more media content */


.moremediacontainer{
  margin-top: 8rem;
}




















/* donation */


.donationboxcontainer{
  width: 80%;
  margin: 2rem auto;
}


.donationboxs{
  display: flex;
  /* background-color: blue; */
}
.donationbox1{
  background-color: #d2301c;
  padding: 4rem;
  color: white;
  margin: 0rem 1rem;
  border-radius: 0rem 0rem 0rem 20rem ;
}

.donationbox2{
/* border: 1px solid black; */
padding: 2rem;

}

.donationpaymentboxs1{
  display: flex;
  /* flex-wrap: wrap; */
  justify-content: space-between;
}
.donationpaymentboxs1 p{
  margin: 0;
}

.donationinput{
  display: flex;
}
.donationinput p{
  text-align: center;
    background-color: blue;
    height: 4rem;
    color: white;
    width: 2rem;
    font-size: 30px;
    padding: 0.4rem;
    font-weight: bold;

}
.donationinput input{
  width: 6rem;
  height: 4rem;
}



.donationpaymentboxs2{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  /* padding: 12px; */
}

.donationpaymentbox3{

  width: 7rem;
    height: 4rem;
    margin: 0.3rem 0.4rem;
    font-size: 20px;
    padding-top: 1rem;
    text-align: center;
    justify-content: center;
  background-color: #9186d4;
  border-radius: 10px;
  border-radius: 1.8rem 0rem;
}

#donationpaymentbox3 p{
  margin: -14px;
}
.donationpaymentbox3:hover{

 
  background-color: blue;
}



.donationpaymentbutton button{
  width: 100%;
  height: 4rem;
  font-size: 33px;
  /* font-weight: bold; */
  margin-top: 1rem;
  background-color: #4cf41a;
  border: none;
  border-radius: 20rem 2rem;
}



.homesec4donations{
  padding-bottom: 1rem;
  color: black;
}



.containers{
  background-color: #ddd;
  border-radius: 12px;
  color: #000;
  height: 19px;
  margin: 0 auto;
  padding: 0;
  width: 83%;
}
  
.skills {
  color: black;
  height: 19px;
  text-align: right; /* Right-align text */
  padding-top: 10px; /* Add top padding */
  padding-bottom: 10px; /* Add bottom padding */
  color: white; /* White text color */
  border-radius: 12px;
  /* width: 83%; */
}
  
.html {
  width: 83%; background-color: #04AA6D;
  color: black;
  font-weight: bold;
} 










/* payment model */


.modalcontainer{
  position: fixed;
  overflow-y: scroll;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  height: 100vh;
  background-color: rgba(189,189,189,0.9);
  z-index: 200;
}

.modelcontent{
  overflow: scroll;
  z-index: 202;
  position: fixed;
  top: 50%;
  left: 50%;
  height: 100vh;
  transform: translate(-50%,-50%);
  background-color: white;
  color: black;
}


.paymentupperpart{
  display: flex;
  margin: 2rem;
}

.payementvalue {
  background-color: blue;

}
.payementvalue p{
  padding: 1rem;
  font-size: 1rem;
  font-weight: bold;
  margin: 0;

}

.paymentinputpart input{
  width: 45rem;
    font-size: 1rem;
    padding: 1rem;
}

.paymentupperpart2{
  display: flex;
  margin: 2rem;
  text-align: center;
  justify-content: center;
}

.payementboxs{
  width: 121px;
  padding: 1rem;
  background-color: yellow;
  margin: 1rem;
  text-align: center;
}
.payementboxs p{
  margin: 0;
}
.payementboxs:hover{
  background-color: blue;
  color: white;
}

#payementboxs{
  padding: 0;
}


.paymentupperpart3{
  margin: 2rem;
}

.paymentmethodlinks{
  display: flex;
}
.paymentmethodlinks p a{
  text-decoration: none;
  padding: 12px;
  color: black;
}



.paymentmidform{
  margin: 2rem;
}

.paymentformde{
  display: flex;
  /* margin: 1rem; */
}
.paymentforminner{
  margin: 0rem 1rem;
}
.paymentinput1 input{
  width: 22rem;
  font-size: 1.2rem;
}

.paymentemail{
  margin: 1rem;
}
.paymentemailinput input{
  font-size: 1.2rem;
  width: 100%;

}







/* 14/04 */

.paymentcheckbox{
  display: flex;
  justify-content: space-between;
  
  margin: 2rem;
}

.paymentcheckinpunt{
  display: flex;
}
.paymentcheckinpunt p{
  margin: 0;
}



.paymentdonatebutton{
  margin: 2rem;
}
#paymentdonatebutton1 button{
  width: 100%;
  margin-top:1rem;
  
}
#paymentdonatebutton1 button:hover{
 width: 100%;
  background-color: blue;
  /* margin: auto; */
}

.paymentdonationtotal{
  display: flex;
  justify-content: center;
  text-align: center;
  
}
.paymentdonationtotal p{
  background-color: blue;
  padding: 1rem;
  text-align: center;

}
.paymentdonationtotal input{
  
  /* padding: 0.4rem; */
  height: 56px;
  width: 38rem;
  

}


.homesec4rasegoal{
  display: flex;
  justify-content: space-between;
}


.closebutton{
  position: absolute;
  top: 12px;
  right: 12px;
}
.closebutton p i{
  font-size: 2.5rem;
  font-weight: bold;
}




.paymentmethodlinks p:hover  {
  color: blue;

}


.termsmenu{
  margin: 2rem;
}







/* ======================== */
/* team */
/* ======================== */



.teamsecboxs{
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  width: 90%;
  margin: 2rem auto;
}
.teamsecboxs .teamsecbox{
  width: 266px;
    height: 300px;
    background-color: transparent;
    margin: 1rem;
    border: 2px solid thistle;
    border-radius: 25px;
    box-shadow: 0px 0px 98px 1px rgb(225, 222, 222);
}

.teamboximg{
  text-align: center;
    width: 150px;
    height: 150px;
    color: red;
    overflow: hidden;
    border-radius: 197px;
    margin: auto;
    margin-top: 2rem;
}
.teamboximg img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}


.teamboxcontent{
  text-align: center;
  padding: 1.5rem 0rem;
}
.teamboxcontent h3{
  font-size: 1.3rem;
}
.teamboxcontent h5{
  font-size: 1rem;
}













/* ========================= */
/* footer */
/* ========================= */

.footercontainer{
/* background-image: linear-gradient(rgb(87, 87, 139),rgb(148, 130, 98)); */
background-image: linear-gradient(White,orange);
}


.footerlogo img{
  width: 9rem;
  box-shadow: 0px 0px 122px 0px #a5a5bd;
  margin-bottom: 1rem;
}

.footerboxs{
  display: flex;
  width: 90%;
  flex-wrap: wrap;
  margin: auto;
  padding: 3rem 0rem;
  justify-content: space-between;
}

.footerbox{
  margin: 1rem;
  width: 14.8rem;
  /* border: 2px solid black; */
}
.footerbox p a{
  color: black;
  text-decoration: none;
  font-size: 1.2rem;
  
}
.footerbox p a:hover{
  color:rgb(19, 18, 18);
  /* background-color: black; */
  text-decoration: none;
  font-size: 1.2rem;
  
}
.footerbox p {
  font-size: 1.2rem;
  
}

.footerbox h1{
  padding-bottom: 1rem;
}


.navlogolinks i{
      padding: 0.3rem;
    font-size: 1.7rem;
}
.navlogolinks a{
  text-decoration: none;
  color: black;
      
}








/* ............................................... */

.aboutimgbox1{
  position: relative;
}
.imghoverbutton1{
  background: rgb(0,0,0,.6);
    /* display: flex; */
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: .6s;
    width: 100%;
}
.imghoverbutton1:hover{
  
    opacity: 1;
    
}
.imghoverbutton1 button{
  
  /* position: absolute; */
  top: 36%;
  border-radius: 12px;
    background-color: orange;
    padding: 0.6rem 1rem;
    height: 3rem;
    margin-top: 9rem;
    
}
.imghoverbutton{
  background: rgb(0,0,0,.6);
    /* display: flex; */
    height: 100%;
    justify-content: center;
    left: 0;
    opacity: 0;
    position: absolute;
    text-align: center;
    top: 0;
    transition: .6s;
    width: 100%;
}
.imghoverbutton:hover{
  
    opacity: 1;
    
}
.imghoverbutton button{
  
  /* position: absolute; */
  top: 36%;
  border-radius: 12px;
    background-color: orange;
    padding: 0.6rem 1rem;
    height: 3rem;
    margin-top: 5rem;
    
}





/* new gallary */

.gallarybox12{
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  /* margin: 1rem; */
}
.piccontainer{
  width: 29%;
  /* display: flex; */
  margin: 1rem;
  /* height: 21rem; */
  
}
.piccontainer img{
  width: 100%;
  height: 100%;

}



  .model{
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1200;
    background-color:transparent;
    display: none;
    text-align: center;
    /* display: flex; */
    /* position: relative; */
  }

  .model::after{
    background: rgba(8, 6, 19, 0.9);
    content: "";
    height: 100%;
    left: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }
 
.modelimgcontainer{
    width: 60%;
    /* height: 100vh; */
    margin: auto;
    display: flex;
    text-align: center;
    justify-content: center;
    padding: 3rem;
}
.modelimgcontainer img{
  width: 100%;
  height: 100%;
  object-fit: cover;

}

  .open{
    display: block;
  }

  .modelimgcontainer h1 i{
    color: white;
  }







}





